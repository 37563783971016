import React, { lazy, useEffect, useState } from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { client } from '../src/index';
import { GetPublicHoliday, GuestUser, verifyToken } from './queries/queries';
import { useJsApiLoader } from '@react-google-maps/api';
import CallbackPage from './pages/CallbackPage';
import { mapLibraries } from './utils/config';
import { useLazyQuery, useMutation } from '@apollo/client';
import { guestTokenId, tokenId } from './commanFunctions';

// import LoginThree from "./pages/auth/loginthree"
// import ForgotPassThree from "./pages/auth/forgot_password/otpandqnapage";
// import ForgotPassTwo from "./pages/auth/forgot_password/forgotpasstwo"
// import NewPassword from "./pages/auth/forgot_password/newpassword";
// import DinerSignupOne from "./pages/diner/dinersignup01";
// import DinerSignupTwo from "./pages/diner/dinersignup02";
// import DinerSignupThree from "./pages/diner/dinersignup03";
// import DinerSignupFour from "./pages/diner/dinersignup04";
// import DinerSignupFive from "./pages/diner/dinersignup05";
// import DinerSignupSix from "./pages/diner/dinersignup06";
// import DinerSignupSeven from "./pages/diner/dinersignup07";
// import DinerSignupEight from "./pages/diner/dinersignup08";
// import DinerSignupNine from "./pages/diner/dinersignup09";
// import DinerSignupTen from "./pages/diner/dinersignup10";
// import { authentication } from "./commanFunctions";
// import { verifyToken } from "./queries/queries";
// import { client } from ".";
// import HostAppLayout from "./pages/auth/HostAppLayout";
// import DinerAppLayout from "./pages/auth/DinerAppLayout";
// import CommonLayout from "./pages/auth/commonLayout";
// import GuestLayout from "./pages/auth/guestLayout";
// import AuthLayout from "./pages/auth/Authlayout";
// import { Inbox } from "react-bootstrap-icons";
// import HostingLanding from "./pages/hostinglanding";
// import IdentityStep02 from "./pages/dinerverifyidentity/identitystep02";
// import IdentityStep03 from "./pages/dinerverifyidentity/identitystep03";
// import IdentityStep04 from "./pages/dinerverifyidentity/identitystep04";
// import HostCribThree from "./pages/host/cribs/hostcribthree";
// import HostMenuthree from "./pages/host/hostmenu/hostmenuthree";
// import HostMenufour from "./pages/host/hostmenu/hostmenufour";
// import NotificationAlert from "./components/dinerpanel/notificationalert";
// import NotificationCenter from "./components/dinerpanel/notificationcenter";
// =========================== All Page=================================
const HostStepOne = lazy(() => import("./pages/host/hoststepone"));
const HostMyProfile = lazy(() => import("./pages/host/hostmyprofile"))
const HostPayment = lazy(() => import("./pages/host/hostPayment/HostPayment"))
const HostReview = lazy(() => import("./pages/host/hostReview/hostReviews"))
const HostCribOne = lazy(() => import("./pages/host/cribs/hostcribone"))
const AddHostCrib = lazy(() => import("./pages/host/cribs/addhostcrib"))
const HostCribTwo = lazy(() => import("./pages/host/cribs/hostcribtwo"))
const HostMenuone = lazy(() => import('./pages/host/hostmenu/hostmenuone'))
const HostMenuAdd = lazy(() => import("./pages/host/hostmenu/hostMenuAdd"))
const HostMenutwo = lazy(() => import("./pages/host/hostmenu/hostmenutwo"))
const AddHostMenuItem = lazy(() => import("./pages/host/hostmenu/AddHostMenuItem"))
const HostMenuEdit = lazy(() => import("./pages/host/hostmenu/hostmenuedit"))
const HostMenufive = lazy(() => import("./pages/host/hostmenu/hostmenufive"))
const MenutemplateSavechange = lazy(() => import("./pages/host/hostmenu/menutemplatesavechange"))
const MenuTempateEdit = lazy(() => import("./pages/host/hostmenu/menutempateedit"))
const HostMarketingone = lazy(() => import("./pages/hostmarketingone"))
const HostMarketingtwo = lazy(() => import('./pages/hostmarketingtwo'))
const HostBusiness = lazy(() => import("./pages/hostbusiness"))
const FindMyMeal = lazy(() => import("./pages/findmymeal/findmeal_one"))
const DinerMyProfile = lazy(() => import("./pages/diner/dinermyprofile"))
const AllReviews = lazy(() => import("./pages/allreviews"))
const CartSummery01 = lazy(() => import("./pages/checkout/cartsummery01"))
const CartCheckout01 = lazy(() => import("./pages/checkout/cartcheckout01"))
const TicketDashboard = lazy(() => import("./pages/helpcentre/ticketdashboard"))
const TicketDashboardTwo = lazy(() => import("./pages/helpcentre/ticketdashboardtwo"))
const HostSignup = lazy(() => import("./pages/host/hostsignup"))
const DinerPayment = lazy(() => import("./pages/diner/dinerpanel/dinerpayment"))
const CloseAccount = lazy(() => import("./pages/diner/closeaccount/myaccount"))
const StripPayment = lazy(() => import('./pages/diner/stripe/stripPayment'))
const TrackYourOrder = lazy(() => import("./pages/checkout/trackyourorder"))
const InboxMessage = lazy(() => import("./pages/inbox/inbox"))
const MemberShip = lazy(() => import("./pages/membership"))
const OrderHistory = lazy(() => import("./pages/orderhistory"))
const NotificationAlert = lazy(() => import("./pages/notificationalert/notificationalert"))
const NotificationCenter = lazy(() => import("./pages/notificationalert/notificationcenter"))
const PrivacySetting = lazy(() => import("./pages/privacysetting"))
const RoyaltyProgram = lazy(() => import("./pages/royaltyprogram"))
const ReferAndEarn = lazy(() => import("./pages/referandearn"))
const UserInterface = lazy(() => import("./pages/userinterface"))
const HelpCenter = lazy(() => import("./pages/helpcentre/helpcenter"))
const HelpCenterDetail = lazy(() => import("./pages/helpcentre/helpcenterdetail"))
const HelpCenterIssue = lazy(() => import("./pages/helpcentre/helpcenterissue"))
const IdentityStep01 = lazy(() => import("./pages/dinerverifyidentity/identitystep01"))
const TermsOfUse = lazy(() => import("./pages/termsofuse"))
const TermsofProgram = lazy(() => import('./pages/termsofprogram'));
const PrivacyPolicy = lazy(() => import("./pages/privacypolicy"))
const InvoicePage = lazy(() => import('./pages/InvoicePage'))
const HostingLanding = lazy(() => import("./pages/hostinglanding"))
const OutletProfile = lazy(() => import("./pages/findmymeal/outletprofile"))
const ReviewAndRating = lazy(() => import("./pages/checkout/reviewandrating"))
const MyOrderA = lazy(() => import("./pages/myordera"))
const SignPass = lazy(() => import("./pages/dinerverifyidentity/signpass"))
const CartSummery = lazy(() => import("./pages/cartsummery"))
const CartCheckout02 = lazy(() => import("./pages/checkout/cartcheckout02"))
const OtpAndQnaPage = lazy(() => import("./pages/auth/forgot_password/otpandqnapage"))
const LoginPage = lazy(() => import("./pages/auth/loginpage"))
const DinerSignup = lazy(() => import("./pages/diner/dinersignup"))
const ForgotPassword = lazy(() => import("./pages/auth/forgot_password/forgotpassword"))
const Home = lazy(() => import("./components/home"))
const AboutUs = lazy(() => import("./pages/aboutus"))
const Media = lazy(() => import("./pages/Media/Media"))
const MediaTwo = lazy(() => import("./pages/Media/mediatwo"))
const Career = lazy(() => import("./pages/career"))
const Career02 = lazy(() => import("./pages/career02"))
const ContactUs = lazy(() => import("./pages/contactus"))
const HostInCentive = lazy(() => import("./pages/Incentive/hostincentive"))
const HostPrestige = lazy(() => import("./pages/HostPrestige/hostprestige"))
const HostPrestigeLeaderBoard = lazy(() => import('./pages/HostPrestige/hostprestigeleaderboard'))
const HostPrestigeLeaderBoard01 = lazy(() => import('./pages/HostPrestige/hostprestigeleaderboard02'))
const HostPrestigeLeaderBoard02 = lazy(() => import('./pages/HostPrestige/hostprestigeleaderboard03'))
const HostPrestigeLeaderBoard03 = lazy(() => import('./pages/HostPrestige/hostprestigeleaderboard04'))
const DinerLoyalty = lazy(() => import("./pages/Loyalty/dinerloyalty"))
const Faq = lazy(() => import("./pages/faq"))
const FaqDetails = lazy(() => import("./pages/faqdetails"))
const Error = lazy(() => import("./pages/error"))
const FavouriteDirectory = lazy(() => import("./pages/favouritedirectory"))
const Favourite = lazy(() => import("./pages/favourite"))
// ============================================================

const useAuth = () => {
    const token = tokenId();
    if (token) {
        return true
    } else {
        return false
    }
}

const MyRoute = () => {
    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: mapLibraries });
    let location = useLocation();
    const auth = useAuth();
    const [loginUser, setLoginUser] = useState({});
    const [userType, setUserType] = useState('guest');
    const [isHost, setisHost] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [routeLoading, setRouteLoading] = useState(true);
    const [headerData, setHeaderData] = useState({
        mode: "pick_up",
        date: new Date(),
        location: { formatted_address: null, lat: null, lng: null },
        cartItems: 0
    });
    const [orderCount, setOrderCount] = useState({
        request: 0,
        current: 0,
        upcoming: 0,
        complete: 0,
        cancel: 0
    });
    const [holidaysList, setHolidaysList] = useState([
        {
            "date": "2023-01-01T00:00:00.000Z",
            "name": "New Year's Day",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-01-02T00:00:00.000Z",
            "name": "New Year's Day observed",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-01-22T00:00:00.000Z",
            "name": "Lunar New Year's Day",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-01-23T00:00:00.000Z",
            "name": "Second Day of Lunar New Year",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-01-24T00:00:00.000Z",
            "name": "Day off for Lunar New Year's Day",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-04-07T00:00:00.000Z",
            "name": "Good Friday",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-04-22T00:00:00.000Z",
            "name": "Hari Raya Puasa",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-05-01T00:00:00.000Z",
            "name": "Labour Day",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-06-02T00:00:00.000Z",
            "name": "Vesak Day",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-06-29T00:00:00.000Z",
            "name": "Hari Raya Haji",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-08-09T00:00:00.000Z",
            "name": "National Day",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-11-12T00:00:00.000Z",
            "name": "Diwali/Deepavali",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-11-13T00:00:00.000Z",
            "name": "Day off for Diwali/Deepavali",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        },
        {
            "date": "2023-12-25T00:00:00.000Z",
            "name": "Christmas Day",
            "type": "National holiday",
            "country": "Singapore",
            "primary_type": "National holiday"
        }
    ]
    )
    const publicHolidayQuery = GetPublicHoliday()
    const [publicHolidayGet] = useLazyQuery(publicHolidayQuery)
    const GetHolidayList = async (currentYear) => {
        const response = await publicHolidayGet({
            variables: { year: currentYear },
            fetchPolicy: 'network-only'
        })
        if (response?.data?.getPublicHoliday?.status === "success") {
            setHolidaysList(response?.data?.getPublicHoliday?.data)
            return (response?.data?.getPublicHoliday?.data)
        }
    }

    useEffect(() => {
        const currentYear = new Date(headerData?.date).getFullYear() || new Date().getFullYear()
        GetHolidayList(currentYear)
    }, [headerData?.date])

    const guestUser = GuestUser();
    const [GuestUserToken] = useMutation(guestUser)
    const getGuestToken = async () => {
        const response = await GuestUserToken();
        console.log(response?.data?.guestUser)
        if (response?.data?.guestUser?.status === "success") {
            localStorage.setItem('guest_token', response.data.guestUser?.data);
        } else {
            console.log(response?.data?.guestUser?.message)
        }
    }

    const getUserData = async () => {
        const tokenData = verifyToken();
        const { data, error } = await client.query({
            query: tokenData
        });
        if (data) {
            if (data?.verifyToken?.status === "success") {
                setLoginUser(data?.verifyToken?.user);
                setUserType(data?.verifyToken?.user?.last_login)
                setisHost(data.verifyToken.user.last_login === "host");
                setIsVerified(data?.verifyToken?.user?.is_verified);
            } else {
                localStorage.removeItem("token");
                if (!guestTokenId()) getGuestToken();
                setLoginUser(null);
                setUserType('guest')
                setisHost(false)
            }
        }
        if (error != undefined) {
            localStorage.removeItem("token");
            if (!guestTokenId()) getGuestToken();
            setLoginUser(null);
            setUserType('guest')
            setisHost(false)
        }
        setRouteLoading(false)
    }

    useEffect(() => {
        getUserData();
    }, [location]);

    useEffect(() => {
        (async () => {
            if (navigator?.geolocation && window?.google) {
                navigator?.geolocation?.getCurrentPosition((position) => {
                    let tempHead = { ...headerData }
                    let latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                    let geoCode = new window.google.maps.Geocoder()
                    geoCode?.geocode({ 'latLng': latlng }, (results, status) => {
                        if (status == window?.google?.maps?.GeocoderStatus?.OK) {
                            tempHead.location.formatted_address = results[0].formatted_address
                            tempHead.location.lat = position.coords.latitude
                            tempHead.location.lng = position.coords.longitude
                            setHeaderData(tempHead)
                        }
                    })

                }, () => {
                    console.log('Unable to retrieve your location');
                });
            }
        })();
    }, [window.google]);

    return (
        <Routes>
            <Route element={<Outlet context={{ user: loginUser, setUser: setLoginUser, header: headerData, setHeader: setHeaderData, count: orderCount, setCount: setOrderCount, holidaysList, setHolidaysList }} />} >

                {/* ====================== HOST ONLY ====================== */}
                {(auth && userType === "host") && <>
                    <Route path="/" exact element={<HostStepOne />} />
                    <Route path="/user_profile" exact element={<HostMyProfile />} />
                    <Route path="/payment" exact element={<HostPayment />} />
                    <Route path="/reviews" exact element={<HostReview />} />

                    <Route path="/cribs" exact element={<HostCribOne />} />
                    <Route path="/new_crib" exact element={<AddHostCrib />} />
                    <Route path="/crib_view/:id" exact element={<HostCribTwo />} />

                    <Route path="/menus" exact element={<HostMenuone />} />
                    <Route path="/new_menu" exact element={<HostMenuAdd />} />
                    <Route path="/menu_view/:id" exact element={<HostMenutwo formType="view" />} />

                    <Route path="/new_menu_item/:id" exact element={<AddHostMenuItem />} />
                    <Route path="/menu_item_edit/:menuid/:id" exact element={<HostMenuEdit formType="edit" />} />

                    <Route path="/templates" exact element={<HostMenufive />} />
                    <Route path="/new_template" exact element={<MenutemplateSavechange />} />
                    <Route path="/template_edit/:id" exact element={<MenuTempateEdit />} />

                    <Route path="/marketing" exact element={<HostMarketingone />} />
                    <Route path="/marketing_dashboard" exact element={<HostMarketingtwo />} />

                    <Route path="/business_insights" exact element={<HostBusiness />} />

                    {/* <Route path="/view-menu-item" exact element={<HostMenuthree />} /> */}
                    {/* <Route path="/menu_edit" exact element={<HostMenutwo formType="add" />} /> */}
                    {/* <Route path="/host/edit-menu-item" exact element={<HostMenufour formType="edit" />} /> */}
                    {/* <Route path="/host/payment" exact element={<DinerPayment />} /> */}

                </>}

                {/* ====================== DINER ONLY ====================== */}
                {(auth && userType === "diner") && <>
                    <Route path="/" exact element={<FindMyMeal />} />
                    <Route path="/user_profile" exact element={<DinerMyProfile />} />
                    <Route path="/reviews" exact element={<AllReviews />} />

                    <Route path="/order_tracking/:id" exact element={<TrackYourOrder />} />

                    <Route path="/ticket_dashboard" exact element={<TicketDashboard />} />
                    <Route path="/ticket_dashboard_view/:id" exact element={<TicketDashboardTwo />} />

                    <Route path="/hostsignup" exact element={<HostSignup />} />
                    <Route path="/payment" exact element={<DinerPayment />} />

                    <Route path="/close_account" exact element={<CloseAccount />} />
                    <Route path="/strip/payment" exact element={<StripPayment />} />

                    <Route path="/favourite_directory" exact element={<FavouriteDirectory />} />
                    <Route path="/favourite/:id/:userId" exact element={<Favourite />} />

                    <Route path="/myOrder" exact element={<MyOrderA />} />
                    <Route path="/hosting" exact element={<HostingLanding />} />
                </>}

                {/* =============== BOTH DINER AND HOST ONLY =============== */}
                {auth && <>
                    <Route path="/inbox" exact element={<InboxMessage />} />
                    <Route path='/membership' exact element={<MemberShip />} />
                    <Route path='/orderhistory' exact element={<OrderHistory />} />
                    <Route path="/notificationalert" exact element={<NotificationAlert />} />
                    <Route path="/notificationcenter" exact element={<NotificationCenter />} />
                    <Route path='/privacysetting' exact element={<PrivacySetting />} />
                    <Route path='/royaltyprogram' exact element={<RoyaltyProgram />} />
                    <Route path="/referandearn" exact element={<ReferAndEarn />} />
                    <Route path="/banner_T&C/:id" exact element={<TermsofProgram />} />
                    <Route path='/userinterface' exact element={<UserInterface />} />

                    <Route path="/helpcenter" exact element={<HelpCenter />} />
                    <Route path="/helpcenter/:id" exact element={<HelpCenterDetail />} />
                    <Route path="/helpcenter/:id/:title/:subtitle" exact element={<HelpCenterIssue />} />
                    <Route path="/helpcenter/:id/:title/:subtitle/:oderid" exact element={<HelpCenterIssue />} />
                    <Route path="/terms-conditions" exact element={<TermsOfUse />} />
                    <Route path="/privacy_policy" exact element={<PrivacyPolicy />} />
                    <Route path="/verification" exact element={<IdentityStep01 />} />
                    <Route path="/view_invoice" exact element={<InvoicePage />} />
                </>}

                {/* ================= BOTH DINER AND GUEST ================= */}
                {(!auth || userType === "diner") && <>

                    <Route path="/findmeal" exact element={<FindMyMeal />} />
                    <Route path="/outletprofile/:id" exact element={<OutletProfile />} />
                    <Route path="/reviewandrating/:id" exact element={<ReviewAndRating />} />
                    <Route path="/cartsummery01" exact element={<CartSummery01 />} />
                    <Route path="/cartcheckout01" exact element={<CartCheckout01 />} />
                    {/* <Route path="/signpass" exact element={<SignPass />} /> */}
                    {/* <Route path="/cartsummery" exact element={<CartSummery />} /> */}
                    {/* <Route path="/cartcheckout02" exact element={<CartCheckout02 />} /> */}
                    {/* <Route path="/otpandqnapage" exact element={<OtpAndQnaPage />} /> */}
                </>}

                {/* ====================== Auth Route ====================== */}
                {!auth && <>
                    <Route path="/login" exact element={<LoginPage />} />
                    <Route path="/signup" exact element={<DinerSignup />} />
                    <Route path="/forgot_password" exact element={<ForgotPassword />} />
                </>}

                {/* ===================== FOR ALL USER ===================== */}
                <Route path="/" exact element={<Home />} />
                <Route path="/aboutus" exact element={<AboutUs />} />
                <Route path="/media" exact element={<Media />} />
                <Route path="/media-two" exact element={<MediaTwo />} />
                <Route path="/career" exact element={<Career />} />
                <Route path="/career02" exact element={<Career02 />} />
                <Route path="/contactus" exact element={<ContactUs />} />
                <Route path="/incentive" exact element={<HostInCentive />} />
                <Route path="/prestige" exact element={<HostPrestige />} />
                <Route path="/prestige-dashboard" exact element={<HostPrestigeLeaderBoard />} />
                <Route path="/prestige-one" exact element={<HostPrestigeLeaderBoard01 />} />
                <Route path="/prestige-two" exact element={<HostPrestigeLeaderBoard02 />} />
                <Route path="/prestige-three" exact element={<HostPrestigeLeaderBoard03 />} />
                <Route path="/loyalty" exact element={<DinerLoyalty />} />
                <Route path="/faq" exact element={<Faq />} />
                <Route path="/faq-detail/:id" exact element={<FaqDetails />} />
                <Route path="/callback" exact element={<CallbackPage />} />
                <Route path="/hostpersonal" exact element={<CallbackPage />} />
                <Route path="/hostbiz-uat" exact element={<CallbackPage />} />
                <Route path="/hostbiz" exact element={<CallbackPage />} />
                <Route path="/favourite=:uId" exact element={<Favourite />} />
                <Route path="/signup_with_referral_code=:referCode" exact element={<DinerSignup />} />
                {/* ====================== Error ======================= */}
                {routeLoading ? <Route path='*' element={<></>} /> : <Route path='*' element={<Error />} />}
            </Route>

        </Routes>

    )
}

export default MyRoute