import { gql } from "@apollo/client";
export const queries = (id) => {
  return gql`
    query outletDetails {
      outletDetails(id: ${id}) {
        status
        message
        data {
          name
          id
          address
          cuisines {
            title
            id
          }
          vendor {
            categories {
              category {
                title
              }
            }
          }
        }
      }
    }
  `;
};

export const LoginHost = () => (
  gql`
    mutation LoginHost($email: String!, $password: String!){
        loginHost(loginData:{
          email:$email,
          password:$password
        }){
          status
          message
          accessToken
          user{
           email
            id
            first_name
            last_name
            last_login
            job_title
            department
            user_role
            employee_number
            auth_login
            role{
              id
              name
            }
          }
        }
      }`
)

export const LoginWithSocialId = () => (
  gql`
    mutation loginWithSocialId(
        $socailLoginProvider:String!,
        $socialLoginId:String!,
        $email:String!,
        $data:JSON){
        loginWithSocialId(user: {
            social_login_provider: $socailLoginProvider,
            social_login_id: $socialLoginId,
            email: $email,
            data: $data,
        }) {
        status
        message
        accessToken
        user{
            id
            first_name
            last_name
            email
            social_login_id
            social_login_provider
        }
        }
    }`
)

export const OutletDetails = () => (
  gql`
  query outletDetails(
    $id: Float!, 
    $long: String, 
    $lat: String) {
    outletDetails(
      id: $id, 
      lat: $lat, 
      long: $long
      ) {
      status
      message
      data {
        vendor_id
        name
        id
        address
        status
        distance
        crib_open_status
        total_review_count
        host_details{
          company_details{
            company_name
            crib_profile
            crib_name
            avg_rate
          }
        }
        outletsTimeSlots {
          outlet_id
          day
          status
          normal_operating_hour
          time_slots {
            start_time
            end_time
          }
        }
        dietary_prefrence
      }
    }
  }`
)

export const getMenuItems = (id) => {
  return gql`
    query menuDetails {
      menuDetails(cuisine_id: 1) {
        status
        message
        data {
          id
          title
        }
      }
    }
  `;
};

export const checkUserExist = (email) => {
  return gql`
  query checkUserExist($email:String!){
      checkUserExist(email:$email)
      {
        status
        message
      }
    }
`;
};

export const hostSignup = () => {
  return gql`
  mutation registeredHost(
    $nricfront: Upload
    $nricback: Upload
    $acradoc: Upload
    $registration_type: String!
    $dob: DateTime!
    $nric_no: String!
    $age: String!
    $gender: String!
    $citizenship: String!
    $address: String!
    $user_role: Float!
    $company_name: String
    $registration_no: String!
    $date_inc: DateTime!
    $company_status: String!
    $primary_business: String!
    $sec_business: String!
    $share_capital: String!
    $designation: String!
    $currency: String!
    $user_host_type: String!
    $rep_citizenship: String!
    $outlets: [OutletRegistrationInput!]!
    $representative: String!
    $rep_nric_no: String!
    $company_amendement: Boolean!
    $profile_amendment: Boolean!
    $company_address: String!
    $first_name:String
    $last_name:String
    $crib_name:String!
    $crib_profile:Upload!  
  ) {
    registeredHost(
      registerdData: {
        registration_type: $registration_type
        dob: $dob
        nric_no: $nric_no
        age: $age
        gender: $gender
        citizenship: $citizenship
        address: $address
        user_role: $user_role
        nricfront: $nricfront
        nricback: $nricback
        acradoc: $acradoc
        company_name: $company_name
        registration_no: $registration_no
        date_inc: $date_inc
        company_status: $company_status
        primary_business: $primary_business
        sec_business: $sec_business
        share_capital: $share_capital
        designation: $designation
        currency: $currency
        user_host_type: $user_host_type
        outlets: $outlets
        representative_citizenship: $rep_citizenship
        representative:$representative
        rep_nric_no: $rep_nric_no
        amendement: $company_amendement
        profile_amendment: $profile_amendment
        company_address: $company_address
        first_name:$first_name
        last_name:$last_name
        crib_name:$crib_name
        crib_profile:$crib_profile  
      }
    ) {
      status
      message
      user {
        id
        email
        last_login
        is_host
        is_verified
      }
    }
  }    
  `;
};

export const dinerSignup = () => {
  return (
    gql`
    mutation AddUser($firstName: String!
      $lastName: String!
      $dob: DateTime
      $gender: String
      $email: String!
      $phoneNumber: String!
      $jobTitle: String!
      $department: String!
      $password: String!
      $empolyeeNumber: String!
      $userRole: Float!
      $referral_code:String
    ) {
      addUser(user: {
          email: $email
          phoneNumber: $phoneNumber
          firstName: $firstName
          lastName: $lastName
          jobTitle: $jobTitle
          department: $department
          password: $password
          employeeNumber: $empolyeeNumber
          userRole: $userRole
          dob: $dob
          gender: $gender
          referral_code: $referral_code
        }
      ) {
        status
        message
        accessToken
        user {
          email
          id
          first_name
          last_name
          job_title
          department
          user_role
          employee_number
          phone_number
          role {
            id
            name
          }
        }
      }
    }    
    `
  )
}

export const getGeoLoc = () => {
  return gql`
  query getLatLong($address: String!) {
    getLatLong(address:$address)
    {
      latitude
      longitude
      formattedAddress
    }
  }
  `;
};

export const SendOtpByEmail = () => {
  return (
    gql`mutation CommonResolver($text: String!) {
      sendOtpToEmailOrPhone(text:$text){
        status
        message
    }
  }`
  )
}

export const verifyOTP = () => {
  return (
    gql`
    mutation CommonResolver($otp: Float!,$text: String!) {
      verifyOTP(otp:$otp, text:$text){
    	status
    	message
  	}
}`
  )
}

export const getSelectedQuestions = () => {
  return (
    gql`
  mutation CommonResolver($text: String!){
    getSelectedQuestions(text:$text){
    	status
    	message
      security_question_by_user{
        user_id
        question_id
        answer
        id
        questions{
          question
          id
        }
      }
  	}
}`
  )
}

export const verifyAnswer = () => {
  return (
    gql`
    mutation CommonResolver(
  $answer: String!,
  $question_id: Float!, 
  $text: String!
){
  verifyAnswer(answer:$answer, 
    question_id:$question_id, 
    text:$text
  ){
    	status
    	message
  	}
}`
  )
}

export const genrateNewPassword = () => {
  return (
    gql`
  mutation CommonResolver(
  $text: String!,
  $password: String!, 
  $confirm_password: String!
){
  genrateNewPassword(text:$text, 
    password:$password, 
    confirm_password:$confirm_password
  ){
    	status
    	message
  	}
}`
  )
}

export const SignUp_EmailPhoneVerification = () => {
  return (
    gql`
    mutation UserOtpVerificationResolver($text:String!){
    SignUp_EmailPhoneVerification(text:$text){
    status
    message
  }
}`
  )
}

export const SignUp_OTPVerification = () => {
  return (
    gql`
    mutation UserOtpVerificationResolver($otp:Float!, $text:String!){
  SignUp_OTPVerification(otp:$otp, text:$text){
    status
    message
  }
}`
  )
}
export const GetUserById = () => {
  return (
    gql`
    query GetUserById($userId: Float!){ 
      getUserById(userId:$userId)
      {
        status
        message
        user{
          id
          employee_number
          status
          first_name
          last_name
          email
          job_title
          department
          user_role
          phone_number
          auth_login
          auth_order
          auth_account_changes
          auth_method
          is_verified
          is_warn_me
          show_quick_view
          diner_status
          is_mobile_verified
          role
          {
            id
            name
          }
          user_profile{
            id
            gender
            dob
            account_verified
            profile_image
            is_allergic
            allergies
            dietary_prefrence
            current_address
            home_address
          }
          emergency_contact{
            id
            first_name
            last_name
            relationship
            phone_number
          }
          security_question{
            question_id
            user_id
            answer
            id
          }
          company_details{
            company_name
            crib_name
            crib_profile
            avg_rate
          }
          social_media_engine
          third_party_tool
          personal_data
        }
        totalRatingCount
      }
    }
   `
  )
}

export const GetHostById = () => {
  return (
    gql`query GetUserById($userId: Float!){ 
      getUserById(userId:$userId)
      {
        status
        message
        user{
          id
          employee_number
          first_name
          last_name
          email
          job_title
          department
          user_role
          phone_number
          auth_login
          auth_order
          auth_account_changes
          auth_method
          user_host_type
          is_host
          last_login
          is_warn_me
          show_quick_view
          host_status
          role
          {
            id
            name
          }
          user_profile{
            id
            gender
            dob
            account_verified
            profile_image
            is_allergic
            allergies
            dietary_prefrence	
            current_address
            home_address
            citizenship
          }
          emergency_contact{
            id
            first_name
            last_name
            relationship
            phone_number
          }
          security_question{
            question_id
            user_id
            answer
            id
          }

         company_details{
          company_name
          crib_name
          crib_profile
          registration_no
          rep_nric_no
          company_status
          address        
        }
          user_document{
            nric_no
            nric_front
            nric_back
            acra_doc
            is_verified
            busniess_certificate
          }
        }
        totalRatingCount
      }
    }
   `)

}

export const GetUsersData = () => {
  return (
    gql`query getUsersdata($userIds:[Float!]$loginRole:String!)
    {
      getUsersdata(userIds:$userIds
      loginRole:$loginRole)
      {
        status
        message
        user
        {
          id
          first_name
          last_name
          email
          user_profile
          {
            profile_image
          }
        }
      }
    }
   `)

}
export const verifyToken = () => {
  return (gql`
  query verifyToken {
    verifyToken {
      status
      user {
        id
        first_name
        last_name
        is_host
        last_login
        email
        phone_number
        is_verified
        host_status
        diner_status
        user_profile
        {
         profile_image 
        }
      }
      message
    }
  }
`)
}

export const GetCribByUser = () => {
  return (gql`
  query getCribByuser {
    getCribByuser{
      status
      message
      outlets {
        id
        name
        address
        lat
        long
        geolocation
        avg_rate
        preparation_time
        is_verified
        verification_doc
        outlet_property
        vendor_id
      }
      total_pages
    }
  }
  
`)
}

export const getCribDetails = () => {
  return (gql`
  query getCribDetails($cribId: Float) {
    getCribDetails(crib_id: $cribId) {
      status
      message
      data {
        status
        id
        name
        address
        lat
        long
        geolocation
        avg_rate
        preparation_time
        is_verified
        verification_doc
        outlet_property
        floor_no
        unit_no
        country
        postal_code
        vendor_id
        building_name
        seating_system
        outlet_property
        crib_publish
        is_deleted
        outletsTimeSlots {
          id
          outlet_id
          normal_operating_hour
          status
          day
          start_time
          end_time
          __typename
        }
        outletTables {
          id
          outlet_id
          table_no
          seats
          __typename
        }
        pickup_collection_inst
        dinein_collection_inst
        is_pickup
        is_dinein
        __typename
      }
      __typename
    }
  }
`)
}

export const GetCompanyDetails = () => {
  return (gql`
  query getComDetails{
    getCompanyDetails{
      status
      message
      company_details{
        id
        crib_profile
        user_id
        company_name
        crib_name
        registration_no
        date_inc   
        company_status
        primary_business
        sec_business
        address
        share_capital
        currency
        representative
        citizenship
        designation
        amendment
        rep_nric_no
        created_at
        updated_at
        avg_rate
        total_review_count
      }
    }
  }
`)
}

export const editDiner = () => {
  return (gql`
  mutation editDiner( 
    $id:Float!,
    $firstName: String!, 
    $lastName: String!,
    $email: String!,
    $phoneNumber: String,
    $password: String!, 
    $userRole: String!,
    $dob: DateTime!, 
    $gender: String!, 
    $dietary_prefrence: [String!],
    $allergies: [String!], 
    $profile_image: Upload, 
    $emergency_contact: [EmergencyContactInput!] !,
    $auth_method: String!, 
    $auth_login: Boolean!, 
    $status: String!, 
    $auth_account_changes: Boolean!,
    $auth_order: Boolean!,
  ) 
  { editDiner(
    user: {
      id: $id,
      email: $email, 
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName 
      password: $password
      userRole: $userRole 
      dob: $dob
      gender: $gender 
      dietary_prefrence: $dietary_prefrence, 
      allergies: $allergies
      profile_image: $profile_image
      auth_login: $auth_login 
      auth_account_changes: $auth_account_changes 
      auth_order: $auth_order 
      auth_method: $auth_method 
      status: $status 
      emergency_contact: $emergency_contact 
    })
    { 
      status 
      message
      user {
        email 
        id
        first_name
        last_name
        job_title 
        department
        user_role
        password 
        employee_number
        role { 
          id 
          name
        } 
        status
        user_profile
        {
          gender 
          dob UpdateHostProfile
          dietary_prefrence 
        } 
      }
    }
  }`)
}

export const switchProfile = () => {
  return (gql`
mutation switchProfile($id: Float!, $profile: String!){
  switchProfile(userId:$id, profile:$profile)
  {
    status
    message
    user{
      id
      first_name
      last_name
      email
      department
      job_title
      employee_number
      phone_number
      auth_method
      user_host_type
      auth_account_changes
      auth_order
      auth_login
      status
      social_login_id
      social_login_provider
      role{
        id
        name
      }
      user_role
      is_host
      last_login
      is_verified
    }
  }
}
`)
}
export const UpdateHostProfile = () => {
  return gql`
  mutation updateHostProfile($first_name: String!,
    $last_name: String!,
    $email: String!,
    $phone_number: String!,
    $gender: String!,
    $dob: DateTime!,
    $current_address: String!,
    $auth_method: String!,
    $auth_login: Boolean!,
    $auth_order: Boolean!,
    $auth_account_changes: Boolean!,
    $allergies: [String!],
    $dietary_prefrence: [String!],
    $nric_no: String!,
    $nric_front: Upload,
    $nric_back: Upload,
    $busniess_certificate: Upload,
    $profile_image: Upload,
    $emergency_contact: [EmergencyContactInput!]!,
    $questions: [QuestionByUser!]!,
    $is_warn_me: Boolean!,
    $show_quick_view: Boolean!,
    $crib_name: String,
    $crib_profile:Upload,
    $user_host_type:String
    ) {
    updateHostProfile(
    user: {
    first_name: $first_name,
    last_name: $last_name,
    email: $email,
    phone_number: $phone_number,
    gender: $gender,
    dob: $dob,
    current_address: $current_address,
    auth_method: $auth_method,
    auth_login: $auth_login,
    auth_order: $auth_order,
    auth_account_changes: $auth_account_changes,
    allergies: $allergies,
    dietary_prefrence: $dietary_prefrence,
    nric_no: $nric_no,
    nric_front: $nric_front,
    nric_back: $nric_back,
    busniess_certificate: $busniess_certificate,
    profile_image: $profile_image,
    emergency_contact: $emergency_contact,
    questions_by_user: $questions,
    is_warn_me: $is_warn_me,
    show_quick_view: $show_quick_view,
    crib_name:$crib_name,
    crib_profile:$crib_profile,
    user_host_type:$user_host_type
    }
    ) {
    status
    message
    user {
    id
    first_name
    last_name
    is_warn_me
    show_quick_view
    user_profile {
    profile_image
    }
    
    }
    }
    }
  `;
}

export const AddnewCrib = () => {
  return gql`
  mutation addNewCrib( 
    $name: String!
    $crib_status: String!
    $address: String!
    $lat: String!
    $long: String!
    $floor_no: String!
    $unit_no: String!
    $country: String!
    $postal_code: String!
    $outlet_time_slots: [OutletTimeSlotsInput!]!
    $pickup_collection_inst: String!
    $dinein_collection_inst: String!
    $is_pickup: String!
    $is_dinein: String!
    $outlet_tables: [OutletTablesInput!]
    $verification_doc:Upload
    $building_name : String!
    $seating_system:Boolean!
    $outlet_property:String!
    $image: [Upload!]
  ) 
  { addNewCrib(
    hostOutletData: {
      name: $name, 
      crib_status: $crib_status
      address: $address
      lat: $lat
      
      long: $long
      floor_no: $floor_no 
      
      unit_no: $unit_no
      country: $country 
      postal_code: $postal_code, 
      
      outlet_time_slots: $outlet_time_slots
      pickup_collection_inst: $pickup_collection_inst
      
      dinein_collection_inst: $dinein_collection_inst 
      is_pickup: $is_pickup 
      is_dinein: $is_dinein 
      outlet_tables: $outlet_tables 
      building_name: $building_name
      seating_system: $seating_system
      verificationdoc:$verification_doc
      outlet_property: $outlet_property
      image:$image
    })
    { 
      status 
      message
      data{
        lat
        long
        vendor_id
        name
        avg_rate
        address
        preparation_time
        geolocation
        outlet_property
        seating_system
        verification_doc
        images
      }
    }
  }
  
  `;
}

// export const UpdateCrib = () => {
//   return gql`
//   mutation updateCrib( 
//     $id: Float!
//     $name: String!
//     $crib_status: String!
//     $address: String!
//     $lat: String!
//     $long: String!
//     $floor_no: String!
//     $unit_no: String!
//     $country: String!
//     $postal_code: String!
//     $outlet_time_slots: [OutletTimeSlotsInput!]!
//     $pickup_collection_inst: String!
//     $dinein_collection_inst: String!
//     $is_pickup: String!
//     $is_dinein: String!
//     $outlet_tables: [OutletTablesInput!]!
//     $verification_doc:Upload
//     $building_name : String!
//     $seating_system:Boolean!
//     $outlet_property:String!

//   ) 
//   { updateCrib(
//     hostOutletData: {
//       id:$id,
//       name: $name, 
//       crib_status: $crib_status
//       address: $address
//       lat: $lat

//       long: $long
//       floor_no: $floor_no 

//       unit_no: $unit_no
//       country: $country 
//       postal_code: $postal_code, 

//       outlet_time_slots: $outlet_time_slots
//       pickup_collection_inst: $pickup_collection_inst

//       dinein_collection_inst: $dinein_collection_inst 
//       is_pickup: $is_pickup 
//       is_dinein: $is_dinein 
//       outlet_tables: $outlet_tables
//       building_name: $building_name
//       seating_system: $seating_system
//       verificationdoc:$verification_doc
//       outlet_property: $outlet_property

//     })
//     { 
//       status 
//       message
//       data{

//         lat
//         long
//         vendor_id
//         name
//         avg_rate
//         address
//         preparation_time
//         geolocation
//         seating_system
//         outlet_property
//         verification_doc
//       }
//     }
//   }

//   `;
// }

export const UpdateCrib = () => {
  return (gql`
  mutation updateCrib( 
    $id: Float!
    $name: String!
    $crib_status: String!
    $address: String!
    $lat: String!
    $long: String!
    $floor_no: String!
    $unit_no: String!
    $country: String!
    $postal_code: String!
    $outlet_time_slots: [OutletTimeSlotsInput!]!
    $pickup_collection_inst: String!
    $dinein_collection_inst: String!
    $is_pickup: String!
    $is_dinein: String!
    $outlet_tables: [OutletTablesInput!]!
    $building_name : String!
   $seating_system:Boolean!
    $verification_doc:Upload
    $outlet_property: String!
  
  
  ) 
  { updateCrib(
    hostOutletData: {
      id:$id,
      name: $name, 
      crib_status: $crib_status
      address: $address
      lat: $lat
      
      long: $long
      floor_no: $floor_no 
      
      unit_no: $unit_no
      country: $country 
      postal_code: $postal_code, 
      
      outlet_time_slots: $outlet_time_slots
      pickup_collection_inst: $pickup_collection_inst
    
      dinein_collection_inst: $dinein_collection_inst 
      is_pickup: $is_pickup 
      is_dinein: $is_dinein 
      outlet_tables: $outlet_tables
      building_name: $building_name
      seating_system: $seating_system
      verificationdoc:$verification_doc
      outlet_property: $outlet_property
    })
    { 
      status 
      message
      data{
        id
        name
        lat
        long
        vendor_id
        name
        avg_rate
        address
        preparation_time
        geolocation
        is_verified
        
      }
    }
  }
  
  
  
  `)
}

export const UpdateCribStatus = () => {
  return (gql`
  mutation updateCribStatus( 
    $outlet_id: Float!
    $crib_status: String!
  ) 
  { updateCribStatus(
    outlet_id:$outlet_id,
    crib_status:$crib_status
  )
    { 
      status 
      message
      data{
        id
        name
        lat
        long
        vendor_id
        avg_rate
        address
        preparation_time
        geolocation
        status
      }
    }
  }`
  )
}

export const UserChangePassword = () => {
  return (gql`
    mutation changePassword(
      $current_password :String!
      $password:String!
      $confirm_password:String!
    ){
      changePassword(
        user:{
          current_password:$current_password
          password:$password
          confirm_password:$confirm_password
        }
      ){
        status
        message
        user{
          id
        }
      }
    }`
  )
}

export const ManageAccountStatus = () => {
  return (gql`
     mutation manageAccountStatus($userId: Float!, $status: String!){
    manageAccountStatus(userId:$userId, status:$status)
    {
      status
      message
      user{
        first_name
        last_name
        email
        department
        job_title
        employee_number
        phone_number
        auth_method
        user_host_type
        auth_account_changes
        auth_order
        auth_login
        status
        social_login_id
        social_login_provider
        role{
          id
          name
        }
        user_role
      }
    }
  }`
  )
}

// host account suspend in api call 
export const manageAccountStatusHost = () => {
  return (gql`
  mutation manageAccountStatus(
    $userId: Float!
    $host_status: String!
  ) {
    manageAccountStatus(
      userId: $userId
      host_status: $host_status
    ) {
      status
      message
      user {
        host_status
        is_pause_order
      }
    }
  }


  `)
}

export const HostUserResolver = () => {
  return (gql`
  query HostUserResolver{
    getQuestions{
    status
    message
    security_question{
    question
    status
    id
    }
    }
    }
  `)
}

// export const UserAccountResolver = () => {
//   return (gql`
//   mutation UserAccountResolver($account_suspend_date:DateTime!, $status: Boolean! ){
//     User_SuspendAccount(account_suspend_date:$account_suspend_date, status:$status ){
//       status
//       message
//     }
//   }
//   `)
// }

export const User_SuspendOtpVerification = () => {
  return (gql`
  mutation User_SuspendOtpVerification($otp:Float! ){
    User_SuspendOtpVerification(otp:$otp, ){
      status
      message
    }
    }
  `)
}

export const User_CloseAccount = () => {
  return (gql`
    mutation User_CloseAccount($reasion:String!, $status: Boolean! ){
      User_CloseAccount(reasion:$reasion, status:$status ){
        status
        message
      }
    }
`)
}


export const loginUserResolver = () => {
  return (gql`
  mutation loginUserResolver($text: String!, $password:String!){
    login_SendOTP(text: $text, password:$password){
      status
      message
      user{
        first_name
        last_name
        email
      }
    }
  }
  `)
}

export const login_OTPVerification = () => {
  return (gql`
  mutation login_OTPVerification($otp:Float!, $text: String!,){
    login_OTPVerification(text:$text, otp: $otp){
      status
      message
    }
  }
  `)
}

export const User_UnSuspendAccount = () => {
  return (gql`
  mutation User_UnSuspendAccount($status:Boolean! ){
    User_UnSuspendAccount(status:$status, ){
      status
      message
      
      user{
        id
        diner_status
        host_status
        first_name
        last_name
        email
        department
        job_title
        user_role
        phone_number
      }
    }
  }
  `)
}

// export const GetMenuCribList = () => (
//   gql`
//   query getMenuCribList{
//   getMenuCribList{
//     status
//     message
//     outlets{
//       id
//       name
//       status
//       outlet_menu{
//         id
//         menu_id
//         publish
//         end_date
//         sold_out_status
//       }
//     }
//   }
// }
// `
// )

export const GetMenuCribList = () => (
  gql`
  query getMenuCribList($menu_id:Float!){
    getMenuCribList(
      menu_id:$menu_id
    ){
      status
      message
      outlets{
        id
        name
        status
        outlet_menu{
          id
          menu_id
          publish
          end_date
          sold_out_status
        }
      }
    }
  }
  `
)

export const GetMenuByCrib = () => (
  gql`
  query getMenuByCrib{
  getMenuByCrib {
    status
    message
		data{
      id
      title
      price
      image
      description
      status
    }
    total_pages
  }
}
  `
)

export const GetMenuDetails = () => (
  gql`
  query getMenuDetails($menuId:Float!) {
  getMenuDetails(
    menu_id:$menuId) {
    status
    message
		data{
      id
      title
      price
      image
      description
      status
      menu_outlets{
        id
        publish
        end_date
        sold_out_status
        outlets{
          name
          id
          status
        }
      }
      menu_items{
        id
        publish
        end_date
        daily_stock_quantity
        sold_out_status  
        items{
          id
          name
        }
      }
    }
  }
}
`
)

export const editMenuDetails = () => (
  gql`
  mutation editMenu(
  $id:Float!,
  $title:String!,
  $status:Boolean!,
  $description:String!
){
  editMenu(menuInput:{
    id:$id,
    title:$title,
    status:$status,
    description:$description,
  }){
    status
    message
     data{
        id
        title
        price
        description
        status
      }
    }
  }`
)

export const addNewMenu = () => (
  gql`
  mutation addMenu(
  $title:String!,
  $status:Boolean!,
  $description:String!
){
  addMenu(menuInput:{
    title:$title,
    status:$status,
    description:$description,
  }){
    status
    message
     data{
        id
        title
        price
        description
        status
      }
    }
  }
`
)

export const SaveItemTemplate = () => (
  gql`
  mutation SaveItemTemplate(
  $name:String!,
  $require_type:String!,
  $select_up_to_choice:String!,
  $item_selections:[ItemsSeclectionsTemplateInput!]
){
  SaveItemTemplate(templateData:{
    name:$name,
    require_type:$require_type,
    select_up_to_choice:$select_up_to_choice,
    item_selections:$item_selections
  }){
    status
    message
    itemOptions{
      id
      user_id
      name
      require_type
      select_up_to_choice
      item_selections{
        key
       price
        name
      }
    }
  }
}
`
)

export const GetMenuItems = () => (
  gql`
  query getItemByMenu($menuId:Float! ,$itemId:Float!){
  getItemByMenu(
    menu_id:$menuId,
    item_id:$itemId) {
    status
    message
		data{
      id
      menu_id
      items{
        name
        description
        image
        base_price
        preparation_time
        dietary_prefrence
        allergies
        item_options{
          id
          name
          item_id
          require_type
          select_up_to_choice
          choice_by_user
          item_selections{
            id
           item_id
            name
            item_option_id
            price
            
          }
        }
      }
    }
  }
                      
}`
)

export const AddMenuItems = () => (
  gql`
  mutation addNewItems( 
  $name: String!
  $description: String!
  $image: Upload
  $base_price: Float!
  $allergies: [String!]!
  $dietary_prefrence: [String!]!
  $items_options: [ItemsOptionsInput!]!
  $menu_id:Float!
  $preparation_time: Float!
) 
{ addNewItems(
	hostMenuItemData:{
  menu_id:$menu_id,
  name:$name,
  description :$description,
  image:$image,
  base_price:$base_price,
  allergies:$allergies,
  dietary_prefrence:$dietary_prefrence,
  items_options:$items_options,
  preparation_time:$preparation_time
  }
)
  { 
    status 
    message
    data{
      id
      name
      image
      description
      base_price
      preparation_time
    }
  }
}`
)

export const UpdateMenuItems = () => (
  gql`
  mutation updateItems( 
    $id:Float!,
    $name: String!
    $description: String!
    $image: Upload
    $base_price: Float!
    $allergies: [String!]!
    $dietary_prefrence: [String!]!
    $item_options: [UpdateItemsOptionsInput!]!
    $menu_id:Float!
    $preparation_time:Float!
  ) 
  { updateItems(
    hostMenuItemData:{
      id:$id,
      menu_id:$menu_id,
      name:$name,
      description :$description,
      image:$image,
      base_price:$base_price,
      allergies:$allergies,
      dietary_prefrence:$dietary_prefrence,
      item_options:$item_options,
      preparation_time:$preparation_time
    }
  )
    { 
      status 
      message
      data{
        id
        name
        image
        description
        base_price
        preparation_time
      }
    }
  }`
)


export const UserProfileVerification = () => {
  return (gql`
  mutation UserProfileVerification(
    $name:String!,
    $dob:DateTime!,
    $gender:String!,
    $citizenship:String!,
    $nric_no:String!,
    $nricfront:Upload,
    $nricback:Upload,
    $address:String!,
    $profile_amendment:Boolean!
  ){
    Update_VerifyMyIdentity(
      userIdentityInput:{
        name:$name,
        dob:$dob,
        gender:$gender,
        citizenship:$citizenship,
        nric_no:$nric_no,
        nricfront:$nricfront,
        nricback:$nricback,
        address:$address,
        profile_amendment:$profile_amendment,
      }
    ){
      status
      message
    }
  }
  `)
}

export const hostGetAllTemplates = () => (
  gql`
  query host_getAllTemplates{
  host_getAllTemplates{
    status
    message
    itemOptions{
      id
      user_id
      name
    }
  }
}
`
)
export const host_createNewTemplate = () => {
  return (gql`
  mutation host_createNewTemplate(
    $name:String!,
    $require_type:String!,
    $select_up_to_choice:String!,
    $item_selections:[ItemsSeclectionsTemplateInput!]
  ){
    host_createNewTemplate(itemInput:{
      name:$name,
      require_type:$require_type,
      select_up_to_choice:$select_up_to_choice,
      item_selections:$item_selections
    }){
      status
      message
    }
  }
  `)
}

export const host_getAllTemplates = () => {
  return (gql`
  query host_getAllTemplates{
    host_getAllTemplates{
    status
    message
    itemOptions{
    id
    user_id
    name
    require_type
    select_up_to_choice
    item_selections{
    key
    name
    price
    }
    }
    }
    }
  `)
}


export const host_findTemplateById = () => {
  return (gql`
  mutation host_findTemplateById( $id:Float! ){
    host_findTemplateById( id:$id ){
      status
      message
      itemOptions{
    id
    user_id
    name
    require_type
    select_up_to_choice
    choice_by_user
    item_selections{
      key
      name
      price
    }
  }
}
}
`)
}


export const host_updateTemplateById = () => {
  return (gql`
  mutation host_updateTemplateById(
    $id:Float!,
    $name:String!,
    $require_type:String!,
    $select_up_to_choice:String!,
    $item_selections:[ItemsSeclectionsTemplateInput!]
    ){
    host_updateTemplateById(itemInput:{
    id:$id,
    name:$name,
    require_type:$require_type,
    select_up_to_choice:$select_up_to_choice,
    item_selections:$item_selections
    }){
    status
    message
    }
    }
  `)
}

export const host_deleteTemplateById = () => {
  return (gql`
  mutation host_deleteTemplateById( $id:Float! ){
    host_deleteTemplateById(itemInput:{ id:$id }){
    status
    message
    }
    }
  `)
}

export const DeleteSelectionItem = () => (
  gql`
mutation deleteSelectionItem($item_selection_id: Float,$item_option_id: Float){
  deleteSelectionItem(
        item_selection_id:$item_selection_id,
item_option_id:$item_option_id
)
{
  status
  message
}
}
`
)

export const host_deleteItemOptionsById = () => {
  return (gql`
  mutation host_deleteItemOptionsById(
    $id:Float!,
    $key:Float!
    ){
    host_deleteItemOptionsById(
    id:$id,
    key:$key
    ){
    status
    message
    itemOptions{
    id
    }
    }
    }
  
  `)
}

export const deleteCribTimeSlot = () => {
  return (gql`
  mutation deleteCribTimeSlot(
    $id:Float!
  ){
    deleteCribTimeSlot(
      id:$id
    ){
      status
      message
      }
    }
  `)

}


export const deleteCribTables = () => {
  return (gql`
  mutation deleteCribTables(
    $id:Float!
  ){
    deleteCribTables(
      id:$id
    ){
      status
      message
      }
    }
  
  `)
}

export const addMenu = () => {
  return (gql`
  mutation addMenu(
    $title:String!,
    $status:Boolean!,
    $description:String!
  ){
    addMenu(menuInput:{
      title:$title,
      status:$status,
      description:$description,
    }){
      status
      message
       data{
          id
          title
          price
          description
          status
        }
      }
    }
  `)
}


export const updateMenuCribStatus = () => {
  return (gql`
  mutation updateMenuCribStatus(
    $menu_id:Float!,
    $crib_id:Float!,
    $status:String!,
    $date:String
  ){
    updateMenuCribStatus(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      status:$status,
      date:$date,
    }){
      status
      message
       data{
          id
        }
      }
    }

  `)
}

export const getMenuCribItemList = () => {
  return (gql`
  query getMenuCribItemList( 
    $cribId:Float!,
    $menuId:Float!
  ){
    getMenuCribItemList(
      crib_id:$cribId,
      menu_id:$menuId
    ){
      status
      message
      data{
        id
        name 			     
        crib_menu_items{
          menu_id
          crib_id
          item_id
          publish
          end_date
          sold_out_status
          daily_stock_quantity
          avaliable_stock_quantity
          sold_out_date
        }
      }
    }
  }
  `)
}

export const updateMenuCribItemStatus = () => {
  return (gql`
  mutation updateMenuCribItemStatus(
    $menu_id:Float!,
    $crib_id:Float!,
    $item_id:Float!,
    $status:Boolean!,
  ){
    updateMenuCribItemStatus(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      item_id:$item_id,
      status:$status,
    }){
      status
      message
       data{
          id
        }
      }
    }
  `)
}

export const refreshMenuItems = () => {
  return (gql`
  mutation refreshMenuItems(
    $menu_id:Float!,
    $crib_id:Float!,
    $crib_items_slots:[RefreshCribItemsSlotsInput!]!
  ){
    refreshMenuItems(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      crib_items_slots:$crib_items_slots,
    }){
      status
      message
       data{
          id
        menu_id
        crib_id
        item_id
        publish
        end_date
        sold_out_status     
        
        }
      }
    }
  `)
}





export const markMenuItemsStockStatus = () => {
  return (gql`
  mutation markMenuItemsStockStatus(
    $menu_id:Float!,
    $crib_id:Float!,
    $item_id:Float!,
    $status:String!, 
    $sold_date:DateTime
  ){
    markMenuItemsStockStatus(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      item_id:$item_id,
      status:$status ,
      sold_date:$sold_date
    }){
      status
      message
       data{
        id
        menu_id
        crib_id
        publish
        end_date
        sold_out_status     
        sold_out_date
        }
      }
    }

  `)
}

export const hostFindTemplateById = () => (
  gql`
  mutation host_findTemplateById( $id:Float! ){
  host_findTemplateById( id:$id ){
    status
    message
    itemOptions{
      id
      user_id
      name
      require_type
      select_up_to_choice
      item_selections{
        key
        name
        price
      }
    }
  }
}
`
)
export const resetStockCount = () => {
  return (gql`
  mutation resetStockCount(
    $menu_id:Float!,
    $crib_id:Float!,
    $item_id:Float!
  ){
    resetStockCount(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      item_id:$item_id    
    }){
      status
      message
       data{
        id
        menu_id
        crib_id
        publish
        end_date
        sold_out_status
        daily_stock_quantity
        avaliable_stock_quantity
        sold_out_date
        
        }
      }
    }
  `)
}

export const deleteItems = () => {
  return (gql`
  mutation deleteItems(
    $item_id:Float!
  ){
    deleteItems(
      item_id:$item_id
    ){
      status
      message
      }
    }
  `)
}


export const cloneMenu = () => {
  return (gql`
  mutation cloneMenu(
    $menu_id:Float!,
    $title:String!
  ){
    cloneMenu(
      menuInput:{
        menu_id:$menu_id,
        title:$title
      }
    ){
      status
      message
      data{
        id
        title
        price
        image
        description
        status
        menu_outlets
        {
          id
          menu_id
          outlet_id
          publish
          end_date
          sold_out_status       
        }
        crib_menu_items{
          id
          crib_id
          item_id
          menu_id
          publish
          end_date
          daily_stock_quantity
          sold_out_status
          items{
            id
            name
            description
            image
            base_price          
          }
        }
      }
      }
    }
  `)
}


export const updateMenuStatus = () => {
  return (gql`
  mutation updateMenuStatus( 
    $menu_id: Float!
    $status: Boolean!
  ) 
  { updateMenuStatus(
    id:$menu_id,
    status:$status
  )
    { 
      status 
      message
      data{
        id
        title
        price
        image
        description
        status
      }
    }
  }
  `)
}


export const getWalletDetail = () => {
  return (gql`
  query {
    getWalletDetail{
      id
      user_id
      name
      description
      balance
      cash_pending
    }
  }
  `)
}



export const takeFromWallet = () => {
  return (gql`
  mutation($amount:Float!) {
    takeFromWallet(amount:$amount){
        status
        message
        data{
          id
          type
          amount
          created_at
          updated_at
          wallet{
              id
              balance
              created_at
              updated_at
          }
        }
    }
  }
  `)
}

export const GetVendorsByLocation = () => (
  gql`query getVendorsByLocation(
    $address: String!
    $filter: FilterInput!
    $keyword: String
    $date: DateTime
    $page_size: Float
    $page: Float
    $sort: String
    $lat: String
    $long: String
    $is_pickup: String
    $is_dinein: String
  ) {
    getVendorsByLocation(
      location: $address
      filter: $filter
      keyword: $keyword
      date: $date
      page_size: $page_size
      page: $page
      sort: $sort
      lat: $lat
      long: $long
      is_pickup: $is_pickup
      is_dinein: $is_dinein
    ) {
      status
      message
      totalItem
      minPrice
      maxPrice
      show_quick_view
      data {
        id
        name
        description
        image
        base_price
        dietary_prefrence
        allergies
        preparation_time
        crib_menu_items {
          id
          menu_id
          crib_id
          item_id
          user_id
          publish
          end_date
          daily_stock_quantity
          avaliable_stock_quantity
          sold_out_status
          sold_out_date
          favouritelist_Data{
            id
            user_Id
            item_Id
            favourite_list_Id
            crib_menu_item_Id
          }
          outlets {
            name
            id
            lat
            long
            avg_rate
            distance
            outletsTimeSlots{
              id
              day
              start_time
              end_time
              status
              normal_operating_hour
            }
            host_details {
              company_details {
                company_name
                crib_profile
                crib_name
                avg_rate
              }
            }
          }
        }
      }
    }
  }
  `
)

export const AddItemCart = () => (
  gql`
  mutation addItemCart( 
    $crib_id: Float!
    $item_id: Float!
    $type:String!
    $quantity:String!
    $item_selection_ids:[Float!]!
    $description:String!
    
  ) 
  { 
    addItemCart(
      cart_items:{
      crib_id:$crib_id,
      item_id:$item_id,
      type:$type,
      quantity:$quantity,
      item_selection_ids:$item_selection_ids,
      description:$description
     }
  )
    { 
      status 
      message
      count
     data{
        cart_key
        user_id
        crib_id
        item_id
        type
        quantity
        description
        item_option_ids
        item_selection_ids
      }
    }
  }
  `
)

export const GetLocationbySglocate = () => (
  gql`
  query getLocationbySglocate(
    $postcode: String
    $streetName: String
    $blockNumber: String
  ) {
    getLocationbySglocate(
      postcode: $postcode
      streetName: $streetName
      blockNumber: $blockNumber
    )
  }`
)

export const DeleteCribDoc = () => (
  gql`
  mutation deleteCribDoc($cribId:Float!){
  deleteCribDoc(cribId:$cribId){
    status
    message
    data{
     id
      name
      lat
      long
      vendor_id
      name
      avg_rate
      address
      preparation_time
      geolocation
      is_verified
      verification_doc
    }
  }
}`
)

export const GetVendors = () => (
  gql`
  query getVendors{
    getVendors{
      name
      id
      avg_rate
      vendor{
        id
        categories{
          category{
            title
          }
        }
      }
    }
  }
  `
)


export const OutletList = () => (
  gql`
  query outletList($crib_id:Float!) {
    outletList(crib_id: $crib_id) {
      status
      message
      outlets {
        name
        id
        address
        status 
        crib_open_status
      }      
    }
  }
  `
)

export const GetMenuListByCrib = () => (
  gql`
  query getMenuListByCrib(
    $crib_id:Float!
  ){
    getMenuListByCrib(
      crib_id:$crib_id
    ) {
      status
      message
      data{
        id
        title
      }
    }
  }`
)

export const GetMenuItemList = () => (
  gql`
  query getMenuItemList( 
    $cribId:Float!,
    $menuId:Float!
  ){
    getMenuItemList(
      crib_id:$cribId,
      menu_id:$menuId
    ){
      status
      message
      data{
        id
        name 
        description
        preparation_time
        image
        base_price
        dietary_prefrence
        allergies
        
      }
    }
  }`
)

export const GetCartItemList = () => (
  gql`
  query getCartItemList(
    $long: String
    $lat: String
    $dateTime: DateTime!
    $type: String!
  ) {
    getCartItemList(long: $long, lat: $lat, dateTime: $dateTime, type: $type) {
      status
      message
      show_quick_view
      data {
        
        crib_details {
          id
          name
          address
          floor_no
          unit_no
          postal_code
          country
          avg_rate
          distance
          preparation_time
          mode
          time
          host_details {
            company_details {
              crib_name
              avg_rate
              crib_profile
            }
          }
        }
        cart_item_details {
          id
          item_id
          crib_id
          cart_key
          type
          quantity
          description
          total_price
          crib_menu_items {
              id
              menu_id
              crib_id
              item_id
              publish
              end_date
              daily_stock_quantity
              avaliable_stock_quantity
              sold_out_status
              sold_out_date
            }
          itemDetails {
            id
            name
            description
            image
            base_price
            dietary_prefrence
            allergies
            item_selections {
              id
              name
              price
            }
          }
        }
        res_details {
          item_purchase
          seat_allowed
          available_seat
        }
        diner_details {
          first_name
          last_name
          email
          phone_number
        }
        collection_detail
        cart_details
        special_request_details
      }
    }
  }
  `
)

export const DeleteItem = () => (
  gql`
  mutation deleteItem( 
    $cart_id: Float!
  ) 
  { 
    deleteItem(
      cart_id:$cart_id
    )
    { 
      status 
      message
      count
    }
  }
  `
)
export const UpdateItemQuantity = () => (
  gql`
  mutation updateItemQuantity( 
    $cart_id: Float!
    $quantity: Float!
  ) 
  { 
    updateItemQuantity(
      quantity:$quantity,
      cart_id:$cart_id
    )
    { 
      status 
      message
        data{
        cart_key
        user_id
        crib_id
        item_id
        type
        quantity
        description
        item_option_ids
        item_selection_ids
      }
    }
  }`
)
export const GetItemDetails = () => (
  gql`
  query getItemDetails( 
    $crib_id: Float!
    $item_id: Float!
    
  ) {
    getItemDetails(
      crib_id:$crib_id,
      item_id:$item_id
  ){
      status
      message
      show_quick_view
      data {
        avaliable_stock_quantity
  outlets{
          id
          name
          address
          preparation_time
          avg_rate
    host_details{
                company_details{
                company_name
                crib_profile
                crib_name
                avg_rate
              }
            }
        }
        items{
          id
          name
          description
          image
          base_price
          dietary_prefrence
          allergies
          item_options{
            id
            user_id
            name
            require_type
            select_up_to_choice
            choice_by_user
            item_selections{
              id
              name
              price
            }
          }
        }
      }
    }
  }`
)

export const UpdateAccountTwoSetup = () => {
  return (gql`
  mutation updateAccountTwoSetup($auth_method: String!, $phone_number: String) {
    updateAccountTwoSetup(
      phone_number: $phone_number
      auth_method: $auth_method
    ) {
      status
      message
      user {
        first_name
        last_name
      }
    }
  }
  `)
}

export const deleteItemImage = () => {
  return (gql`
  mutation deleteItemImage($itemId:Float!)
  {
    deleteItemImage(itemId:$itemId)
    {
      status
      message
      data
      {
        id
        image
      }
    }
  }
  
  `)
}

export const checkoutOrders = () => {
  return (gql`
  mutation checkoutOrders( 
    $crib_id: Float!
    $discount:Float!
    $cutleries:Boolean!
    $delivery_charges:Float!
    $tax:Float!
    $total_price:Float!
    $grand_total:Float!
    $time:DateTime!
    $date:String!
    $card_id:String!
    $promo_code_discount:Float!
    $credit_wallet_discount:Float!
    $credit_wallet_status:Boolean!
    $cash_wallet_discount:Float!
    $cash_wallet_status:Boolean!
  ) 
  { 
    checkoutOrders(
      order_input:{
          crib_id:$crib_id,
          discount:$discount,
          cutleries:$cutleries,
          delivery_charges:$delivery_charges,
          tax:$tax,
          total_price:$total_price,
          grand_total:$grand_total,
          time:$time,
          date:$date,
          card_id:$card_id
          promo_code_discount: $promo_code_discount
          credit_wallet_discount: $credit_wallet_discount
          credit_wallet_status: $credit_wallet_status
          cash_wallet_discount: $cash_wallet_discount
          cash_wallet_status: $cash_wallet_status
      }
    )
  }
  `)
}

export const GetCardList = () => (
  gql` query getCardList{
  getCardList
}`)

export const CheckFoodWarning = () => (
  gql`query checkFoodWarning{
    checkFoodWarning
  }`
)

export const GetCartCheckoutDetails = () => (
  gql`query getCartCheckoutDetails($long:String,$lat:String,$dateTime:DateTime!){
    getCartCheckoutDetails(long:$long,lat:$lat,dateTime:$dateTime)
    {
      status
      message
      show_quick_view
      data{
         cart_item_details{
          cart_key
          type
          quantity
          description
          total_price
          itemDetails{
          id
          name
          description
          image
          base_price
          dietary_prefrence
          allergies
          item_selections{
            id
            name
            price
          }
        }
        }
        cart_summary
        collection_detail
        diner_details{
          first_name
          last_name
          email
          phone_number
          auth_method
          user_profile{
            current_floor_no
            current_unit
            current_address
            current_pincode          
          }
        }  
        crib_details{
          id
          name
          address
          preparation_time
        }
        special_request_details
      }
    }
  }
  `
)
export const ProceedToCheckout = () => (
  gql`
  mutation proceedToCheckout(  
    $crib_id: Float!
    $discount:Float!
    $cutleries:Boolean!
    $delivery_charges:Float!
    $tax:Float!
    $total_price:Float!
    $grand_total:Float!
    $seat:Float!
    $distance: Float!
    $is_special_request:Boolean!
    $special_request:[specialRequestInput!]
    $total_special_request_ammount:Float
    
  ) 
  { 
    proceedToCheckout(
      checkout_inputs:{
          crib_id:$crib_id,
          discount:$discount,
          cutleries:$cutleries,
          delivery_charges:$delivery_charges,
          tax:$tax,
          total_price:$total_price,
          grand_total:$grand_total,
          seat:$seat,
          distance:$distance,
        is_special_request:$is_special_request,
        special_request:$special_request,
        total_special_request_ammount:$total_special_request_ammount,     
      }
    )
  }`
)
export const GetCurrentOrderList = () => (
  gql`
  query getCurrentOrderList{
    getCurrentOrderList{
      status
      message
      data{
        id
        user_id
        crib_id
        order_number
        payment_status
        payment_intent_id
        time
        distance
        order_status
        type
        cutleries
        crib_details{
          id
          name
          address
        }
        order_items{
          id
            order_id
          item_id
          name
          price
          quantity
          image
          description
          item_details{
            id
            image
            name
            base_price
            dietary_prefrence
            allergies
            preparation_time
            item_selections{
              id
              name
              price
              }            
          }
        }
      }
    
    }
  }`
)

export const GetCloseOrderList = () => (
  gql`
  query getCloseOrderList{
    getCloseOrderList{
    status
      message
      data{
        id
        user_id
        crib_id
        order_number
        payment_status
        payment_intent_id
        time
        
        order_status
        type
        cutleries
        crib_details{
          id
          name
          address
        }
        order_items{
          id
          order_id
          item_id
          name
          price
          quantity
          image
          description
          item_details{
            id
            image
            name
            base_price
            dietary_prefrence
            allergies
            preparation_time
            item_selections{
              id
              name
              price
              }
          }
        }
      }
    
    }
  }
  `
)

export const CheckCartValidation = () => (
  gql`
  query checkCartValidation($crib_id:Float!){
    checkCartValidation(crib_id:$crib_id)
  }`
)

export const getTrackOrderDetails = () => (
  gql`
  query getTrackOrderDetails($order_id:Float!){
    getTrackOrderDetails(order_id:$order_id){
      status
      message
      show_quick_view
      data{
        id
        user_id
        crib_id
        order_number
        payment_status
        payment_intent_id
        time
        distance
        order_status
        type
        cutleries
        special_request
        crib_details{
          id
          name
          address
          unit_no
          floor_no
          postal_code
        }
        order_items{
          id
          order_id
          item_id
          name
          price
          image
          description
          quantity
          item_details{
            id
            image
            name
            base_price
            dietary_prefrence
            allergies
            preparation_time
            item_selections{
              id
              name
              price
              }
          }          
        }
        order_tracking{
          id
          order_id
          status
          time
          message
        } 
      }
    }
  }
  `
)

export const ChangeCartMode = () => (
  gql`
  query changeCartMode{
    changeCartMode
  }`
)

export const CheckCartItemCount = () => (
  gql`
    query checkCartItemCount{
      checkCartItemCount{
        status
        message
        data
      }
    }
  `
)

export const getOrderList = () => (
  gql`
 query getOrderList($crib_id:Float $sort_by:String $status:String){
  getOrderList(
    crib_id:$crib_id
    sort_by:$sort_by
    status:$status){
		status
    message
    count
    is_pause
    data{
      id
      user_id
      crib_id
      order_number
      payment_status
      payment_intent_id
      time
      order_status
      type
      cutleries
      created_at
      cancel_by
      user_details{
        first_name
        last_name
        user_profile{
          profile_image
        }
      }
      crib_details{
        id
        name
        address
      }
      order_items{
        quantity
        id
       	 order_id
        item_id
        name
        price
        image
        description
        item_details{
          id
          image
          name
          base_price
          dietary_prefrence
          allergies
          preparation_time
          item_selections{
            id
          	name
            price
	          }
        }
        
      }     
      is_special_request
      special_request
      total_special_request_ammount
    }
  
  }
}
 `
)

export const getOrderItemDetails = () => (
  gql`
  query getOrderItemDetails($order_item_id:Float!){
    getOrderItemDetails(
    order_item_id:$order_item_id
    ){
    status
    message
    data{
    id
    order_id
    item_id
    name
    price
    time
    image
    description
    quantity
    item_details{
    id
    name
    description
    image
    base_price
    dietary_prefrence
    allergies
    item_options{
    id
    name
    item_selections{
    id
    name
    item_id
    price
    }
    }
    }
    order_details{
    id
    user_id
    crib_id
    order_number
    price
    total_price
    payment_status
    time
    order_status
    type
    crib_details{
    id
    name
    }
    user_details{
      first_name
      last_name
      user_profile{
        profile_image
      }
    }
    }
    
    }
    }
    }
  `
)

export const acceptDinerOrder = () => {
  return gql`
    mutation acceptDinerOrder($order_id:Float!){
    acceptDinerOrder(order_id:$order_id)
  }
`
}

export const declineDinerOrder = () => {
  return gql`
  mutation declineDinerOrder($order_id: Float!, $OrderStatus: String!) {
    declineDinerOrder(order_id: $order_id, OrderStatus: $OrderStatus)
  }
  `
}

export const updateOrderStatus = () => {
  return gql`
  mutation updateOrderStatus($order_id:Float! ,$status:String!){
    updateOrderStatus(order_id:$order_id ,status:$status)
  }
  `
}


export const GetFaqMasterList = () => (
  gql`
  query getFaqMasterList($search:String!,$pageSize:Float,$page:Float){
    getFaqMasterList(search:$search , page_size:$pageSize , page:$page)
    {
      status
      message
     faq_master{
      id
      name
      status
      faq_child{
        id
        question
      }
    }
      total_records
    }
  }
  `
)

export const GetFaqChildList = () => (
  gql`
  query getFaqChildList($faq_id:Float!){
    getFaqChildList(faq_id:$faq_id)
    {
      status
      message
       faq_child{
        id
        faq_master_id
        question
        answer
        status
      }
      total_records
    }
  }`
)

export const GetHelpCenterMasterList = () => (
  gql`
  query getHelpCenterMasterList{
    getHelpCenterMasterList
    {
      status
      message
       data{
          id
          slug
          name
          image
          status
        }
    }
  }
`
)

export const GetSubjectList = () => (
  gql`
  query getSubjectList($master_id:Float!){
    getSubjectList(master_id:$master_id)
    {
      status
      message
       data{
          id
          slug
          name
          status
        master{
          id
          slug
          name
          image
          status
        }
        }
    }
  }
`
)

export const SubmitHelpCenterUserRequest = () => (
  gql`
  mutation submitHelpCenterUserRequest($user_id:Float!,$email:String!,$help_category:String!,$subject:String!,$type_id:String,$desc:String!,$doc:Upload)
    {
      submitHelpCenterUserRequest(
        user_input:{
            user_id:$user_id,
            email:$email,
            help_category:$help_category,
            subject:$subject,
            type_id:$type_id,
            desc:$desc,
            doc:$doc,
        }
      )
      {   
        status  
        message
        
    }
    }
`
)

export const GetUserHelpCenterList = () => (
  gql`
  query getUserHelpCenterList($status:String!){
    getUserHelpCenterList(status:$status)
    {
      status
      message
       data{
          id
          user_id
          email
          ticket_number
          created_at
          cetgory_detail{
            id
            slug
            name
            image
          }
          help_category
          subject
          status
          type_id
          desc
          doc
          chat_detail{
            id
            helper_id
            sender_id
            receiver_id
            status
            is_mute
            is_seen
            seen_count
            messages{
              id
              sender_id
              receiver_id
              message
              message_type
              created_at
            }
          }
        }
    }
  }
  `
)

export const hostGetReceviedReviewRatingList = () => (
  gql`
  query hostGetReceviedReviewRatingList($is_feedback:Boolean!){
    hostGetReceviedReviewRatingList(is_feedback:$is_feedback)
    {
      status
      message
       data{
         id
        order_id
        diner_id
        host_id
        ratings
        is_feedback
        is_reply
        desc
        host_reply
        images
        created_at
        updated_at
        host_images
        order_details{
          id
          order_number
          type
          crib_details{
            name
          }
        }
        diner_details{
          first_name
          last_name
          user_profile{
            profile_image
          }
        }
        host_details{
          company_details{
            company_name
            crib_name
            crib_profile
            avg_rate
          }
        }
        }
      rating_details
    }
  }
  `
)

export const hostRevert = () => (
  gql`
  mutation hostRevert($id:Float!,$order_id:Float!,$is_feedback:Boolean!,$isReply:Boolean!,$hostReply:String!,$hostImages:[Upload!])
    {
      hostRevert(
        review_input:{
            id:$id,
            order_id:$order_id,
            is_feedback:$is_feedback,
            is_reply:$isReply,
            host_reply:$hostReply,
            host_images:$hostImages
        }
      )
      {   
        status  
        message
        data{
          id
          order_id
          diner_id
          host_id
          ratings
          is_feedback
          is_reply
          desc
          host_reply
          images
          host_images
        }
    }}
  `
)

export const hostDeleteReviewRatingFeedback = () => (
  gql`
  mutation hostDeleteReviewRatingFeedback($id:Float!,$is_feedback:Boolean!)
  {
    hostDeleteReviewRatingFeedback(
      id:$id,
      is_feedback:$is_feedback
    )
    {   
      status  
      message
  }}
  
  `
)
export const DinerSubmitReviewRatingsFeedback = () => (
  gql`
  mutation dinerSubmitReviewRatingsFeedback($order_id:Float!,$rating:Float!,$is_feedback:Boolean!,$image:[Upload!],$desc:String!)
    {
      dinerSubmitReviewRatingsFeedback(
        review_input:{
          order_id:$order_id,
          rating:$rating,
          is_feedback:$is_feedback,
          desc:$desc,
          images:$image
        }
      )
      {   
        status  
        message
        data{
          id
        }
    }
}
`
)

export const GetTransactionList = () => (
  gql`
  query getTransactionList{
    getTransactionList{
      status
      message
      data{
     id
      order_id
        user_id
        trx_no
        user_type
        payment_method
        payment_status
        ammount
        created_at
        updated_at
        order_details{
          crib_details{
            name
            address
          }
  ticket_details{
            id
            user_id
            email
          }
  
        }
        
      }
    }
  }
  `
)

export const CheckReviewFeedback = () => (
  gql`
  query checkReviewFeedback($key:Boolean!, $order_id:Float!){
    checkReviewFeedback(key:$key , order_id:$order_id){
      status
      message
      data{
           id
          order_id
        }
      show
    }
  } 
  `
)

export const GetReviewFeedbackDetails = () => (
  gql`
  query getReviewFeedbackDetails($id:Float!){
    getReviewFeedbackDetails(id:$id){
      status
      message
      data{
           id
          order_id
        diner_id
        host_id
        ratings
        is_feedback
        is_reply
        desc
        host_reply
        images
        host_images
        }
    }
  }`
)

export const UpdateDinerReview = () => (
  gql`
  mutation updateDinerReview($id:Float!,$order_id:Float!,$rating:Float!,$is_feedback:Boolean!,$image:[Upload!],$desc:String!)
    {
      updateDinerReview(
        review_input:{
          id:$id
          order_id:$order_id,
          rating:$rating,
          is_feedback:$is_feedback,
          desc:$desc,
          images:$image
        }
      )
      {   
        status  
        message
        data{
          id
          order_id
          ratings
          is_feedback
          is_reply
          desc
          images
          
        }
    }
    }
`
)

export const DinerDeleteImage = () => (
  gql`
  mutation dinerDeleteImage($id:Float!,$order_id:Float!,$is_feedback:Boolean!,$image_name:String!)
    {
      dinerDeleteImage(
        review_input:{
            id:$id,
            order_id:$order_id,
            is_feedback:$is_feedback,
            image_name:$image_name
        }
      )
      {   
        status  
        message
        data{
          id
          order_id
          diner_id
          host_id
          ratings
          is_feedback
          is_reply
          desc
          host_reply
          images
          host_images
        }
    }}
`
)
export const hostEditRevert = () => (
  gql`
  mutation hostEditRevert($id:Float!,$order_id:Float!,$is_feedback:Boolean!,$isReply:Boolean!,$hostReply:String!,$hostImages:[Upload!])
  {
    hostEditRevert(
      review_input:{
          id:$id,
          order_id:$order_id,
          is_feedback:$is_feedback,
          is_reply:$isReply,
          host_reply:$hostReply,
          host_images:$hostImages
      }
    )
    {   
      status  
      message
      data{
        id
        order_id
        diner_id
        host_id
        ratings
        is_feedback
        is_reply
        desc
        host_reply
        images
        host_images
      }
  }}
  `
)

export const DinerGetReviewRatingList = () => (
  gql`
  query dinerGetReviewRatingList{
    dinerGetReviewRatingList
    {
      status
      message
       data{
         id
        order_id
        diner_id
        host_id
        ratings
        is_feedback
        is_reply
        desc
        host_reply
        images
        created_at
        order_details{
          id
          order_number
          type
        }
        host_details{
          company_details{
            crib_name
            crib_profile
            avg_rate
          }
        }
        }
    }
  }
`
)

export const DinerDeleteReviewRatingFeedback = () => (
  gql` mutation dinerDeleteReviewRatingFeedback($id:Float!)
    {
      dinerDeleteReviewRatingFeedback(
        id:$id,
      )
      {   
        status  
        message
    }}
`
)

export const GetUserTicketDetails = () => (
  gql`query getUserTicketDetails($id:Float!){
    getUserTicketDetails(id:$id)
    {
      status
      message
       data{
          id
          user_id
          email
          ticket_number
          help_category
          subject
          status
          type_id
          desc
          doc
        created_at
        chat_detail{
          id
          helper_id
          sender_id
          receiver_id
          status
          is_mute
          is_seen
          seen_count
          messages{
            id
            sender_id
            receiver_id
            message
            message_type
            created_at
          }
        }
        }
    }
  }
`
)

export const DinerSendHelpCenterMessages = () => (
  gql`mutation dinerSendHelpCenterMessages($id:Float! , $ticket_id:Float! , $message:String , $message_type:String! , $message_doc:Upload){
    dinerSendHelpCenterMessages(
      message_input:{
              id:$id,
        			ticket_id:$ticket_id,
        			message:$message,
        			message_type:$message_type,
        			message_doc:$message_doc
      }
    )
    {
      status
      message
     data{
          id
          user_id
          email
          ticket_number
          help_category
          subject
          status
          type_id
          desc
          doc
        created_at
        chat_detail{
          id
          helper_id
          sender_id
          receiver_id
          status
          is_mute
          is_seen
          seen_count
          messages{
            id
            sender_id
            receiver_id
            message
            message_type
            created_at
          }
        }
        }
    }
  }
`
)

export const SetMuteNotification = () => (
  gql`mutation setMuteNotification($id:Float! , $is_mute:Boolean!){
    setMuteNotification(id:$id, is_mute:$is_mute)
    {
      status
      message
       data{
          id
          user_id
          email
          ticket_number
          help_category
          subject
          status
          type_id
          desc
          doc
        created_at
        chat_detail{
          id
          helper_id
          sender_id
          receiver_id
          status
          is_mute
          is_seen
          seen_count
          messages{
            id
            sender_id
            receiver_id
            message
            message_type
            created_at
          }
        }
        }
    }
  }`
)

export const GetNotificationSettingList = () => (
  gql`query getNotificationSettingList{
    getNotificationSettingList
    {
      status
      message
      data{
        id
        name
        slug
        status
        created_at
        updated_at
      }
    }
  }
`
)

export const SetUserNotificationSetting = () => (
  gql`mutation setUserNotificationSetting(
    $notification_id:Float!,
    $notification_slug:String!,
    $push:Boolean!,
    $reminder:String,
    $email:Boolean!,
    $sms:Boolean!,
    $status:Boolean!,
  ){
      setUserNotificationSetting(
        setting_input:{
         notification_id:$notification_id,
          notification_slug:$notification_slug,
          push:$push,
          reminder:$reminder,
          email:$email,
          sms:$sms,
          status:$status,
        }
      )
      {
        status
        message
       data{
          id
        user_id
        notification_id
        notification_name
        push
        reminder
        email
          sms
        status
        created_at
        updated_at
        
      }
    }
  }
  `
)
export const hostDeleteRevertImage = () => (
  gql`
  mutation hostDeleteRevertImage($id:Float!,$order_id:Float!,$is_feedback:Boolean!,$image_name:String!)
  {
    hostDeleteRevertImage(
      review_input:{
          id:$id,
          order_id:$order_id,
          is_feedback:$is_feedback,
          image_name:$image_name
      }
    )
    {   
      status  
      message
      data{
        id
        order_id
        diner_id
        host_id
        ratings
        is_feedback
        is_reply
        desc
        host_reply
        images
        host_images
      }
  }}
  `
)

export const pauseUnpauseNewOrder = () => (
  gql`
  mutation pauseUnpauseNewOrder($status:Boolean!)
  {
    pauseUnpauseNewOrder(
      status:$status
    )
    {   
      status  
     message
      user{
        first_name
        last_name
        email
        is_pause_order
      }
  }}
  `
)
// ********************* this api is Order List Count ***************************

export const getOrderListCount = () => (
  gql`
  query getOrderListCount{
    getOrderListCount
    }
  `
)

// ******************************************************************************

export const GetUserNotificationSetting = () => (
  gql`query getUserNotificationSetting{
    getUserNotificationSetting
    {
      status
      message
      data{
        id
        name
        slug
        user_setting{
          id
          user_id
          notification_id
          notification_name
          push
          reminder
          email
          sms
          status
        }
      }
    }
  }
`
)

export const DinerGetOrderReviewRatingList = () => (
  gql`query dinerGetOrderReviewRatingList($hostId:Float!,$sort:String!,$page:Float!,$page_size:Float!){
    dinerGetOrderReviewRatingList(host_id:$hostId,sort:$sort,page:$page,page_size:$page_size)
    {
      totalReviews
      status
      message
       data{
         id
        order_id
        diner_id
        host_id
        ratings
        is_feedback
        is_reply
        desc
        host_reply
        images
        created_at
        order_details{
          id
          order_number
          type
        }
        diner_details{
          first_name
          last_name
          user_profile{
            profile_image
          }
        }
        }
      rating_details
    }
  }
`
)

export const DinerCancelOrder = () => (
  gql`mutation dinerCancelOrder($order_id:Float!){
    dinerCancelOrder(order_id:$order_id)
    {
      status
      message
     data{
        id
        user_id
        crib_id
        order_number
        price
        total_price
        payment_status
      	time
      
    }
  }
}`
)

// stripe save card api in diner panel

export const addNewCard = () => (
  gql`
  mutation addNewCard($name:String!,$exp_year:Float!,$exp_month:Float!,$card_number:Float!,$cvv:Float!){
    addNewCard(
      card_input:{
          exp_month:$exp_month,
          exp_year:$exp_year,
          card_number:$card_number,
          cvv:$cvv,
          name:$name
      }
    )
  }`
)


// stripe save card delete api in diner panel

export const deleteCard = () => (
  gql`
  mutation deleteCard($card_id:String!){
    deleteCard(
          card_id:$card_id
    )
  }
  `
)

// stripe edit card api in diner panel

export const editUserCard = () => (
  gql`
  mutation editUserCard($card_id:String!,$name:String!,$exp_year:Float!,$exp_month:Float!){
    editUserCard(
      card_input:{
          exp_month:$exp_month,
          exp_year:$exp_year,
          name:$name,
          card_id:$card_id
      }
    )
  }`
)

// stripe getCardDetails api in diner panel

export const getCardDetails = () => (
  gql`
  query getCardDetails( $card_id: String!) {
    getCardDetails( card_id: $card_id)
  }
  `
)

// help center api url dynamic pass 
export const getOrderHelpCenterDetails = () => (
  gql`
  query getOrderHelpCenterDetails{
    getOrderHelpCenterDetails
    {
      status
      message
				data{
        id
        slug
        name
        image
          child{
            id
            master_id
            name
            slug
          }
      }
    }
  }
  `
)

export const GetHostTransactionList = () => (
  gql`
  query getHostTransactionList{
    getHostTransactionList
    {
      status
      message
data{
      		id
          host_id
          diner_id
          order_id
          ammount
          admin_ammount
          admin_status
          payment_status
          created_at
          updated_at
          
          diner_details{
            first_name
            last_name
            user_profile{
              home_floor_no
              home_unit
              home_address
              home_pincode
              current_floor_no
              current_unit
              current_address
              current_pincode
              current_floor_no
              
            }
          }
          order_details{
            payment_intent_id
            ticket_details{
              id
              user_id
              email
              ticket_number
              
            }
          }
        }
    }
  }

  `
)


// stripe Add bank Api in host panel
export const addBankAccount = () => (
  gql`
    mutation addBankAccount(
      $country:String!,
      $currency:String!,
      $account_holder_name:String!,
      $account_holder_type:String! 
      $routing_number:Float!
      $account_number:String!
    ){
      addBankAccount(
       account_detail:{
          country:$country,
          currency:$currency,
          account_holder_name:$account_holder_name,
          account_holder_type :$account_holder_type,
          routing_number:$routing_number,
          account_number:$account_number
        }

      )
    }
`
)

// stripe Get Bank Account Details in host panel
export const getBankAccount = () => (
  gql`
    query getBankAccount{
      getBankAccount
    }
  `
)


// stripe Update Bank Account Details in host panel

export const updateBankAccount = () => (
  gql`
    mutation updateBankAccount(
      $account_holder_name:String!,
      $account_holder_type:String! 
    ){
      updateBankAccount(
       account_detail:{
          account_holder_name:$account_holder_name,
          account_holder_type :$account_holder_type,
        }

      )
    }
  `
)


// stripe Delte Bank Account Details in host panel

export const DeleteBankAccount = () => (
  gql`
  mutation deleteBankAccount($account_id:String!){
    deleteBankAccount(account_id:$account_id)
    }
    `
)
export const GetCartItemDetails = () => (
  gql`query getCartItemDetails($cart_item_id:Float!){
    getCartItemDetails(
      cart_item_id:$cart_item_id
    ){
    status
    message
    data{
      id
      cart_key
      user_id
      crib_id
      item_id
      type
      quantity
      description
      item_option_ids
      item_selection_ids
      itemDetails{
        id
        name
        description
        image
        base_price
        dietary_prefrence
        allergies
        preparation_time        
           item_selections{
            id
            name
            price
            items_options{
              id
              name
              require_type
            }
          }
      }
    }
    
  }
}`
)

export const UpdateItemCart = () => (
  gql`mutation updateItemCart(
    $id:Float! ,
    $crib_id:Float! , $item_id:Float! ,
    $quantity:String! ,$description:String!,
    $item_selection_ids:[Float!]!
    ){
    updateItemCart(
      cart_items:{
        id:$id,
        crib_id:$crib_id,
        item_id:$item_id,
        quantity:$quantity,
        description:$description
        item_selection_ids:$item_selection_ids
     }
    ){
        status
        message
        data
      {
        id
        cart_key
        user_id
        crib_id
        item_id
        type
        quantity
        description
        item_option_ids
        item_selection_ids
      }
    }
  }
  `
)

export const GetOutletMenuItemList = () => (
  gql`query getOutletMenuItemList($crib_id: Float!) {
    getOutletMenuItemList(crib_id: $crib_id) {
      status
      message
      show_quick_view
      data {
        id
        title
        value
      }
    }
  }
  `
)

export const ChangePrivacySetting = () => (
  gql`mutation changePrivacySetting($personal_data:Boolean!,$third_party_tool:Boolean!,$social_media_engine:Boolean!){
    changePrivacySetting(profile:{
      personal_data:$personal_data,
      third_party_tool:$third_party_tool,
      social_media_engine:$social_media_engine      
    })
    {
      status
      message
    user{
      first_name
      last_name
      email
      social_media_engine
      third_party_tool
      personal_data
      is_pause_order
    }      
    }
  }
`
)

// this api is a withdraw wallet amount in host panel
export const withdrawWalletAmount = () => (
  gql`
  mutation withdrawWalletAmount($amount:Float!){
    withdrawWalletAmount(amount:$amount)
  }
  `
)
export const SetReadMessage = () => (
  gql`mutation setReadMessage($id:Float!){
    setReadMessage(id:$id)
    {
      status
      message
       data{
          id
          user_id
          email
          ticket_number
          help_category
          subject
          status
          type_id
          desc
          doc
        created_at
        chat_detail{
          id
          helper_id
          sender_id
          receiver_id
          status
          is_mute
          is_seen
          seen_count
          messages{
            id
            sender_id
            receiver_id
            message
            message_type
            created_at
          }
        }
      }
    }
  }
`
)

export const GetInvoiceDetails = () => (
  gql`
  query getInvoiceDetails($orderId:Float!){ 
    getInvoiceDetails(order_id:$orderId)	
}`
)

// this api is a make primary card
export const makePrimaryCard = () => (
  gql`
  mutation makePrimaryCard($card_id:String!){
    makePrimaryCard(
          card_id:$card_id
    )
  }
  `
)

export const GetUserPromoCodes = () => (
  gql`
  query getUserPromoCodes($page:Float!,$page_size:Float!) {
    getUserPromoCodes(page:$page,page_size:$page_size) {
      status
      message
     totalReords
      data_arr {
        id
        promo_code_id
        user_id
        status
        admin_promo_code_master {
          id
          name
          metadata {
            id
            name
          }
          duration
          duration_in_months
          currency
          amount_off
          percent_off
          campaign_Name
          description
          code
          image
          min_amount_spend
          max_amount_spend
          limit
          max_appied_limit
          user_type
          specifyUsers
          product_type
          host_campaign
          host_experience
          host_experience_level_type
          host_stars
          host_stars_level_type
          host_review
          host_review_level_type
          host_conquest
          host_legue
          host_legue_level_type
          status
          is_deleted
          card_type
          bank_type
          start_time
          end_time
          specifyUsers
          marketing_campaign
          created_at
          updated_at
        }
      }
    }
  }      
  `
)

export const ApplypromoCodeOnCart = () => (
  gql`mutation applypromoCodeOnCart($userPromoCodeId: Float!) {
    applypromoCodeOnCart(userPromoCodeId: $userPromoCodeId) {
      status
      message
    }
  }
  `
)

export const RemovePromoCodeToCart = () => (
  gql`query removePromoCodeToCart {
    removePromoCodeToCart {
      status
      message
    }
  }
  `
)


export const host_closeAccount = () => (
  gql`
    mutation host_closeAccount($host_status: String!) {
      host_closeAccount(host_status:$host_status) {
        status
        message
        user{
          host_status
          is_pause_order
        }
      }
    }
  `
)

export const User_SuspendAccount = () => (
  gql`
  mutation User_SuspendAccount($account_suspend_date:DateTime!, $status: Boolean!,
    $diner_status:String!){
    User_SuspendAccount(account_suspend_date:$account_suspend_date, status:$status,
      diner_status:$diner_status){
      status
      message
        user{
          diner_status
        }
        
    }
  }
  `
)

export const DeleteEmergencyContact = () => (
  gql`mutation deleteEmergencyContact($id:Float!){
    deleteEmergencyContact(id:$id){
      status
      message
    }
  }
  `
)

export const DinerGetAllFavouriteList = () => (
  gql`query diner_getAllFavouriteList{
    diner_getAllFavouriteList{
      status
      message
      data_arr{
        id
        title
        status
        created_at
        updated_at
      }
    }
  }`
)

export const DinerAddFavouriteList = () => (
  gql`mutation diner_addFavouriteList(
    $title: String
    $status:Boolean
 ){
  diner_addFavouriteList(
   favouritelistInput:{title: $title,  status: $status}
 )
   {
     status
     message
     data{
       id
       title
       status
       created_at
       updated_at
     }
   }
 }
 `
)

export const DinerAddUserFavouriteList = () => (
  gql`mutation diner_addUserFavouriteList(
    $status: Boolean!
    $favourite_list_id: Float!
    $crib_menu_item_Id: Float!
  ) {
    diner_addUserFavouriteList(
      userInput: {
        status: $status
        favourite_list_Id: $favourite_list_id
        crib_menu_item_Id: $crib_menu_item_Id
      }
    ) {
      status
      message
      data {
        id
        status
        item_Id
        crib_id
        menu_id
        crib_menu_item_Id
        favourite_list_Id
        created_at
        updated_at
      }
    }
  }
  `
)

export const DeleteQuestionById = () => (
  gql`mutation deleteQuestionById($id: Float!) {
    deleteQuestionById(id: $id){
      status
      message
    }
  }`
)

export const DinerGetAllData = () => (
  gql`query diner_getAllData {
    diner_getAllData {
      status
      message
      data_arr {
        id
        title
        user_id
        status
        favourite_list_items {
          id
          user_Id
          item_Id
          favourite_list_Id
          crib_menu_item_Id
          status
          item_data {
            id
            name
            image
          }
        }
      }
    }
  }`
)

export const DinerGetAllItemsDetailsById = () => (
  gql`query diner_getAllItemsDetailsById(
    $id: Float!
    $location: String
    $lat: Float
    $long: Float
    $user_id: Float!
    $page:Float!
    $page_size:Float!
  ) {
    diner_getAllItemsDetailsById(
      id: $id
      location: $location
      lat: $lat
      long: $long
      user_id:$user_id
      page:$page
      page_size:$page_size
    ) {
      status
      message
      show_quick_view
      total_items
      data {
        id
        title
        favourite_list_items {
          favourite_list_Id
            id
            user_Id
            item_Id
          item_data {
            id
            name
            description
            image
            base_price
            dietary_prefrence
            allergies
            preparation_time
          }
          crib_menu_items {
            id
            crib_id
            menu_id
            item_id
            publish
            end_date
            sold_out_status
            daily_stock_quantity
            avaliable_stock_quantity
            sold_out_date
            avaliable_stock_quantity
            daily_stock_quantity
            avaliable_stock_quantity
            sold_out_status
            sold_out_date
            favouritelist_Data {
              id
              user_Id
              item_Id
              favourite_list_Id
              crib_menu_item_Id
            }
            host_details {
              company_details {
                id
                company_name
                crib_profile
                registration_no
                date_inc
                company_status
                primary_business
                sec_business
                address
                share_capital
                currency
                representative
                citizenship
                designation
                amendment
                rep_nric_no
                created_at
                updated_at
                crib_name
                crib_profile
                avg_rate
                total_review_count
              }
            }
            outlets {
              id
              lat
              long
              vendor_id
              name
              avg_rate
              address
              geolocation
              verification_doc
              is_verified
              pickup_collection_inst
              floor_no
              unit_no
              postal_code
              dinein_collection_inst
              is_pickup
              is_dinein
              status
              crib_publish
              is_deleted
              outlet_property
              country
              building_name
              seating_system
              distance
              mode
              time
              crib_open_status
              total_review_count
              dietary_prefrence
              outletsTimeSlots{
                id
                outlet_id
                day
                start_time
                end_time
              }
            }
          }
        }
      }
    }
  }`
)

export const GetReviewImages = () => (
  gql`query getReviewImages($host_id: Float!, $outlet_id: Float!) {
    getReviewImages(host_id: $host_id, outlet_id: $outlet_id) {
      status
      message
      media_gallery {
        id
        host_id
        outlet_id
        type
        image
      }
      reviewdata {
        id
        host_id
        diner_id
        order_id
        ratings
        images
      }
    }
  }`
)

export const DinerDeleteFavouriteList = () => (
  gql`mutation diner_deleteFavouriteList($id: Float!) {
    diner_deleteFavouriteList(id: $id) {
      status
      message
    }
  }
  `
)

export const DinerDeleteParticularItem = () => (
  gql`mutation diner_deleteParticularItem($id: Float!) {
    diner_deleteParticularItem(id: $id) {
      status
      message
    }
  }`
)

export const DinerUpdateFavouriteList = () => (
  gql`mutation diner_updateFavouriteList(
    $id:Float!,
   $title:String,
   $status:Boolean
 ){
    diner_updateFavouriteList(favouritelistInput:{id:$id, title:$title,status:$status}){
      status
      message
      data{
          id
          title 
          status
          created_at
          updated_at
      }
    }
  }`
)

//  This api is upload media gallery
export const host_uploadMediaGallery = () => (
  gql`
  mutation host_uploadMediaGallery(
    $outlet_id: Float!
    $image: [Upload!]
  ) {
    host_uploadMediaGallery(
      hostMediaGalleryInput: { outlet_id: $outlet_id, image: $image }
    ) {
      status
      message
      data {
        id
        host_id
        outlet_id
        type
        image
      }
    }
  }`
)

//  This api is get all media gallery Details 
export const host_getAllMediaGalleryDetails = () => (
  gql`
  query host_getAllMediaGalleryDetails($outlet_id:Float!){
    host_getAllMediaGalleryDetails(outlet_id:$outlet_id){
      status
      message
      data_arr{
        id
        host_id
        outlet_id
        type
        image
      }
    }
  }
  `
)

// This api is delete media gallery image 
export const host_deleteMediaGalleryById = () => (
  gql`
    mutation host_deleteMediaGalleryById($id:Float!){
      host_deleteMediaGalleryById(id:$id){
        status
        message
      }
    }
  
  `
)

// singpass APIs
export const SingpassGetMyInfoConfigData = () => (
  gql`query singpass_getMyInfoConfigData{
    singpass_getMyInfoConfigData
  }`
)

export const SingpassGenrateMyInfoCodeChallenge = () => (
  gql`query singpass_genrateMyInfoCodeChallenge{
    singpass_genrateMyInfoCodeChallenge
  }`
)

export const SingpassGetMyInfoPersonData = () => (
  gql`mutation singpass_getMyInfoPersonData($authCode: String!, $sId: String!) {
    singpass_getMyInfoPersonData(authCode: $authCode, sId: $sId) {
      status
      message
      data
    }
  }`
)

// singpass business APIs
export const SingpassGetMyInfoBuisConfigData = () => (
  gql`query singpass_getMyInfoBuisConfigData {
    singpass_getMyInfoBuisConfigData
  }`
)

export const SingpassGetEntityPersonData = () => (
  gql`mutation singpass_getEntityPersonData($code:String!){
    singpass_getEntityPersonData(code:$code)
  }`
)

export const GuestUser = () => (
  gql`mutation guestUser{
    guestUser
  }`
)

export const CloneFavouriteList = () => (
  gql`mutation cloneFavouriteList($favourite_list_id:Float! $userId:Float!){
    cloneFavouriteList(favourite_list_id:$favourite_list_id, userId:$userId){
     status
     message
     data{
      id
       user_id
       title
       status
       value
   }}}`
)

// ********************Referral and Earn****************
export const GetUserReferralCode = () => (
  gql`query getUserReferralCode {
    getUserReferralCode
  }`
)

export const CheckReferralCodeIsValid = () => (
  gql`mutation checkReferralCodeIsValid($referralCode: String!) {
    checkReferralCodeIsValid(referralCode: $referralCode)
  }`
)

export const GetAllreferralBanners = () => (
  gql`mutation getAllreferralBanners {
    getAllreferralBanners {
      status
      message
      data_arr {
        id
        program_name
        program_start_date
        program_type
        program_end_date
        status
        banner
        created_at
      }
    }
  }`
)

export const ViewDetailsOfReferralProgram = () => (
  gql`mutation viewDetailsOfReferralProgram($referralId: Float!) {
    viewDetailsOfReferralProgram(referralId: $referralId) {
      status
      message
      data {
        id
        program_name
        program_type
        banner
        terms_and_conditions
        created_at
      }
    }
  }`
)

export const GetAllJoinedReferralUsesHistory = () => (
  gql`
  mutation getAllJoinedReferralUsesHistory(
    $page: Float
    $page_size: Float
    $userType: String!
    $userStatus: String!
  ) {
    getAllJoinedReferralUsesHistory(
      page: $page
      page_size: $page_size
      userType: $userType
      userStatus: $userStatus
    ) {
      status
      message
      total_pages
      data_arr {
        id
        referral_program_id
        referral_id
        referrer_userId
        referree_userId
        give_referree_amount
        get_referrer_amount
        program_join_user_type
        program_start_date
        user_program_join_date
        user_status
        is_cron
        created_at
        updated_at 
         user_details{
          id
          first_name
          last_name
          user_profile{
            id
            profile_image
          }
        }
      }
    }
  }`
)

export const GetPublicHoliday = () => (
  gql`query getPublicHoliday($year:Float!){
    getPublicHoliday(year:$year)
  }`
)

export const OCRFetchTextFromImage = () => (
  gql`mutation OCR_fetchTextFromImage($file: Upload, $docKey: String!) {
    OCR_fetchTextFromImage(ocrDocInput: { file: $file, docKey: $docKey })
  }`
)

export const Singpass_getDinerMyInfoConfigData = () => (
  gql`query singpass_getDinerMyInfoConfigData{
    singpass_getDinerMyInfoConfigData
  }`
)

export const Singpass_genrateDinerMyInfoCodeChallenge = () => (
  gql`query singpass_genrateDinerMyInfoCodeChallenge{
    singpass_genrateDinerMyInfoCodeChallenge
  }`
)

export const Singpass_getDinerMyInfoPersonData = () => (
  gql`mutation singpass_getDinerMyInfoPersonData($authCode: String!, $sId: String!) {
    singpass_getDinerMyInfoPersonData(authCode: $authCode, sId: $sId) {
      status
      message
      data
    }
  }`
)

export const IsUser2FaISTrue = () => (
  gql`mutation isUser2FaISTrue($key: String!, $userText: String) {
    isUser2FaISTrue(key: $key, userText: $userText)
  }`
)

export const VerifyOTP = () => (
  gql`mutation verifyOTP($otp: Float!,$text: String!) {
    verifyOTP(otp:$otp, text:$text){
        status
        message
    }
  }`
)

// this api is Terms And Privacy Policy
export const getTermsAndPrivacyPolicy = () => (
  gql`
  query getTermsAndPrivacyPolicy($type: String!) {
    getTermsAndPrivacyPolicy(type: $type) {
      status
      message
      data {
        id
        title
        description
        type
      }
    }
  }
  `
)