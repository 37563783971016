import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { SingpassGetEntityPersonData, SingpassGetMyInfoPersonData, Singpass_getDinerMyInfoPersonData } from '../queries/queries';
import LoadingModal from '../components/spinner/LoadingModal';
import AlertPopup from '../components/alertPopup';
import { HiCheckCircle } from 'react-icons/hi';

const CallbackPage = () => {
    const [show, setShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const getPersonData = SingpassGetMyInfoPersonData()
    const [PersonData] = useMutation(getPersonData)

    const getDinerPersonData = Singpass_getDinerMyInfoPersonData()
    const [DinerPersonData] = useMutation(getDinerPersonData)

    const getDinerBusinessPersonData = SingpassGetEntityPersonData()
    const [DinerBusinessData] = useMutation(getDinerBusinessPersonData)

    const PostPersonData = async (AuthCode, sId) => {
        const checkProcess = localStorage?.getItem("myinfo_type")
        if (checkProcess === "hostsignup") {
            let response = await PersonData({ variables: { authCode: AuthCode, sId: sId } })
            if (response?.data?.singpass_getMyInfoPersonData?.status === "success") {
                sessionStorage.removeItem("sid")
                localStorage.setItem("host_sign_up", `${4}-${'personal'}-${'singpass'}`)
                localStorage.setItem("myinfo", JSON.stringify(response?.data?.singpass_getMyInfoPersonData?.data))
                window.location = process.env.REACT_APP_PUBLIC_URL + "hostsignup"
                localStorage?.removeItem("myinfo_type")
            }
        } else if (checkProcess === "verifyprocess") {
            let response = await DinerPersonData({ variables: { authCode: AuthCode, sId: sId } })
            if (response?.data?.singpass_getDinerMyInfoPersonData?.status === "success") {
                sessionStorage.removeItem("sid")
                localStorage.setItem("verify_process", `${3}-${'singpass'}`)
                localStorage.setItem("myinfo", JSON.stringify(response?.data?.singpass_getDinerMyInfoPersonData?.data))
                window.location = process.env.REACT_APP_PUBLIC_URL + "verification"
                localStorage?.removeItem("myinfo_type")
            }
        } else if (checkProcess === "hostbusiness") {
            let authCode = AuthCode;
            if (AuthCode?.includes("&state")) {
                authCode = AuthCode?.split("&state")[0]
            }
            let response = await DinerBusinessData({ variables: { code: authCode } })
            if (response?.data?.singpass_getEntityPersonData?.status === "success") {
                localStorage.setItem("host_sign_up", `${4}-${'business'}-${'singpass'}`)
                localStorage.setItem("myinfobusiness", JSON.stringify(response?.data?.singpass_getEntityPersonData?.data))
                window.location = process.env.REACT_APP_PUBLIC_URL + "hostsignup"
                localStorage?.removeItem("myinfo_type")
            }
        }
    }

    useEffect(() => {
        const pageUrl = window.location.href;
        const sId = JSON.parse(sessionStorage?.getItem('sid')) || ""
        const AuthCode1 = pageUrl?.split('hostpersonal?code=')[1];
        const AuthCode2 = pageUrl?.split('callback?code=')[1];
        const AuthCode3 = pageUrl?.split('hostbiz?code=')[1];
        const AuthCode4 = pageUrl?.split('hostbiz-uat?code=')[1];

        if (AuthCode1) {
            PostPersonData(AuthCode1, sId)
        } else if (AuthCode2) {
            PostPersonData(AuthCode2, sId)
        } else if (AuthCode3) {
            PostPersonData(AuthCode3, sId)
        } else if (AuthCode4) {
            PostPersonData(AuthCode4, sId)
        } else {
            console.log("error")
        }



        // if (AuthCode1 || AuthCode2 || sId) {
        //     if (AuthCode1) {
        //         PostPersonData(AuthCode1, sId)
        //     } else {
        //         PostPersonData(AuthCode2, sId)
        //     }
        // }
    }, [])
    return (
        <>
            <LoadingModal />
            <AlertPopup
                show={show}
                onHide={() => setShow(false)}
                icon={<svg width="94" height="86" viewBox="0 0 94 86" fill="none" className="mt-5 mb-3" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                </svg>}
                title={errorMessage?.status}
                description={errorMessage?.message}
            />
        </>
    )
}

export default CallbackPage