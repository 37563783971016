import React from 'react'
import { Modal } from 'react-bootstrap'
import { Loader } from 'rsuite'

const LoadingModal = () => {
    return (
        <Modal centered show={true} className='bg-transparent' contentClassName='text-center bg-transparent border-0'>
            <Modal.Body>
                <Loader size='lg' className='loadercolor' />
            </Modal.Body>
        </Modal>
    )
}

export default LoadingModal