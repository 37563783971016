import moment from "moment";
import { allergiesOptions, dietaryPrefrences } from "./utils/staticData";

export const tokenId = () => localStorage.getItem('token');
export const guestTokenId = () => localStorage.getItem('guest_token');

export const addClass = () => {
  document.getElementById("signUpFlip").classList.add("scalIn");
  setTimeout(function () {
    document.getElementById("scalOut").classList.add("scalOut");
  }, 100);

  setTimeout(function () {
    document.getElementById("signUpFlip").classList.add("flip");
  }, 1000);

  setTimeout(function () {
    document.getElementById("scalOut").classList.remove("scalOut");
  }, 1000);

  setTimeout(function () {
    document.getElementById("signUpFlip").classList.remove("scalIn");
  }, 1000);
}

export const signUpPageClass = () => {
  document.getElementById("signUpFlip").classList.toggle("flip");
}

export const arrayFilterWithSameData = (primary_array, secondary_array) => {
  if (primary_array) {
    let selected_array = primary_array?.map((data) =>
      secondary_array && (secondary_array?.find((item) => (item.value === data)))
    )
    return selected_array
  }
}

export const arrayFilterWithoutSameData = (primary_array, secondary_array) => {

  if (primary_array) {
    let u_arr = [...secondary_array];

    u_arr.forEach((el, index) => {
      if (primary_array.indexOf(el.value) >= 0) {
        delete u_arr[index]
      }
    })
    let unselected_array = u_arr.filter(() => true);
    return unselected_array
  }
}

export const MonthNames = (index) => {
  let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return index ? monthNames[index] : "January"
};

export const handleLocationLatLng = (Id) => {
  if (window?.google && Id) {
    let google = window?.google;
    const geoCode = new google.maps.Geocoder();
    let result = geoCode?.geocode({ placeId: Id }).then(({ results }) => {
      if (results[0]) {
        return results[0]
      } else {
        console.log("No results found");
      }
    }).catch((e) => console.log("Geocoder failed due to: " + e));
    return result;
  }
}

export const PositiveNumberOnly = (event) => (['event', 'E', '+', '-'].includes(event.key) && event.preventDefault());

export const PriceFormat = (value) => {
  let result = value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return result
}

export const CreateFormattedAddress = ({ buildingNumber, streetName, floor, unit, country, postalCode }) => {
  const bNum = buildingNumber ? (buildingNumber + " ") : ""
  const sName = streetName ? (streetName + ", ") : ""
  const fl = floor ? ("#" + floor + " ") : ""
  const ut = unit ? (unit + ", ") : ""
  const ctry = country ? (country + " ") : ""
  const pCode = postalCode
  return (bNum + sName + fl + ut + ctry + pCode)
}

export const DateFormate = (date) => new moment(date)?.format("DD MMMM yyyy");

export const EmailValidation = (value) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value));

export const PasswordValidation = (value) => (new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")?.test(value))

export const getDayObject = (day) => {
  let res;
  switch (day) {
    case 0:
      res = {
        id: 0,
        title: 'Sunday',
        short: 'sun',
        long: 'sunday'
      }
      break;
    case 1:
      res = {
        id: 1,
        title: 'Monday',
        short: 'mon',
        long: 'monday'
      }
      break;
    case 2:
      res = {
        id: 2,
        title: 'Tuesday',
        short: 'tue',
        long: 'tuesday'
      }
      break;
    case 3:
      res = {
        id: 3,
        title: 'Wednesday',
        short: 'wed',
        long: 'wednesday'
      }
      break;
    case 4:
      res = {
        id: 4,
        title: 'Thursday',
        short: 'thur',
        long: 'thursday'
      }
      break;
    case 5:
      res = {
        id: 5,
        title: 'Friday',
        short: 'fri',
        long: 'friday'
      }
      break;
    case 6:
      res = {
        id: 6,
        title: 'Saturday',
        short: 'sat',
        long: 'saturday'
      }
      break;
    default:
      res = undefined
      break;
  }
  return res;
}

export const TimeFormat12 = (time = '12:00') => {
  let [hour, minute] = time?.split(':')
  let timeType = hour > 12 ? "PM" : "AM"
  hour = hour > 12 ? `${hour - 12}` : hour
  return { hour, minute, timeType }
}

export const DayDifferenceByWeek = (baseDay, weekDay) => {
  if (baseDay < weekDay) {
    return (weekDay - baseDay)
  } else if (baseDay > weekDay) {
    return ((7 - baseDay) + weekDay)
  } else {
    return 0
  }
}

export const TimeDuration = (bt, ct) => {
  const current_time = new Date(bt);
  const mth = new Date(bt).getMonth();
  const dy = new Date(bt).getDate();
  const yr = new Date(bt).getFullYear();
  const distance_time = new Date(`${(mth + 1)}/${dy}/${yr}, ${ct}`);
  const distance = (distance_time - current_time);
  const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
  const seconds = Math.floor((distance % (60 * 1000)) / 1000);
  return { hours, minutes, seconds }
}

export const CurrentTimeCompare = (bt, ct) => {
  let currentTime = moment(bt).format("HH:mm")
  let compareTime = moment(ct, "HH:mm").format("HH:mm")
  if (compareTime > currentTime) {
    return "greater"
  } else if (compareTime == currentTime) {
    return "equal"
  } else if (compareTime < currentTime) {
    return "smaller"
  }
}

export const CheckOutletStatus = (outletArray, date) => {
  let result;
  const outletSort = (a, b) => (a?.start_time?.split(":")[0] - b?.start_time?.split(":")[0])
  outletArray?.sort(outletSort)
  for (let index = 0; index < outletArray?.length; index++) {
    let startTime = CurrentTimeCompare(date, outletArray[index]?.start_time)
    let endTime = CurrentTimeCompare(date, outletArray[index]?.end_time)
    if (startTime === "greater") {
      result = { status: 'outlet-close', data: outletArray[index] }
      break;
    } else if ((startTime === "smaller" || startTime === 'equal') && (endTime === 'greater' || endTime === 'equal')) {
      result = { status: 'outlet-open', data: outletArray[index] }
      break;
    } else {
      console.log('next-check')
    }
  }
  return result
}

// export const CheckNextOutlet = (outletArr, day, initDay) => {
//   let initday = getDayObject(new Date(initDay).getDay()) || getDayObject(new Date().getDay());
//   let outletTime = outletArr?.filter((item) => (item?.day === day))
//   let result = {};
//   const outletSort = (a, b) => (a?.start_time?.split(":")[0] - b?.start_time?.split(":")[0])
//   outletTime?.sort(outletSort)
//   if (outletTime && outletTime?.length > 0) {
//     for (const ot of outletTime) {
//       let startTime = CurrentTimeCompare(initDay, ot?.start_time)
//       let endTime = CurrentTimeCompare(initDay, ot?.end_time)
//       if (ot?.day === initday?.short) {
//         if (startTime === "greater") {
//           result = { data: ot, status: 'outlet-close' }
//           break;
//         } else if ((startTime === "smaller" || startTime === 'equal') && (endTime === 'greater' || endTime === 'equal')) {
//           result = { data: ot, status: 'outlet-open' }
//           break;
//         } else {
//           console.log("check next")
//         }
//       } else {
//         result = { data: ot, status: 'outlet-close' }
//       }
//     }
//   } else {
//     console.log("check next")
//   }
//   return result
// }

export const CheckNormalOutletStatus = (weekDayList, date) => {
  const currentDay = date ? getDayObject(new Date(date).getDay()) : getDayObject(new Date().getDay())
  let checkedDay = currentDay
  let displayStatus = false
  let displayTime = '12:00'
  let timeDuration = "0 Minutes"
  let isBreake = false

  if (weekDayList?.length > 0) {
    {/* found week days list where status is true */ }
    for (let index = 0; index <= 6; index++) {
      {/* check outlet day when outlet status found open aur close*/ }
      const outletTime = weekDayList?.filter((item) => (item?.day === checkedDay?.short))
      const outletSort = (a, b) => (a?.start_time?.split(":")[0] - b?.start_time?.split(":")[0])
      outletTime?.sort(outletSort)
      if (isBreake) break
      if (outletTime && outletTime?.length > 0) {
        {/* check outlet time when outlet will open */ }
        if (currentDay === checkedDay) {
          {/* if check day is current day then check time condition */ }
          for (let ot of outletTime) {
            const startTime = CurrentTimeCompare(date, ot?.start_time)
            const endTime = CurrentTimeCompare(date, ot?.end_time)
            {/* when outlet day and loop outlet day equal */ }
            if (ot?.day === checkedDay?.short) {
              if (startTime === "greater") {
                displayStatus = true
                const { hour, minute, timeType } = TimeFormat12(ot?.start_time)
                displayTime = (`${hour}:${minute} ${timeType}`)
                const duration = TimeDuration(date, ot?.start_time)
                const dayDiff = DayDifferenceByWeek(currentDay?.id, checkedDay?.id)
                if (dayDiff > 0) {
                  timeDuration = (`${dayDiff} Days`)
                } else if (duration?.hours > 0) {
                  timeDuration = (`${duration?.hours} Hours : ${duration?.minutes + 1} Minutes`)
                } else {
                  timeDuration = (`${duration?.minutes + 1} Minutes`)
                }
                isBreake = true
                break
              } else if ((startTime === "smaller" || startTime === 'equal') && (endTime === 'greater' || endTime === 'equal')) {
                displayStatus = false
                isBreake = true
                break
              }
            }
          }
        } else {
          {/* if check day is not current day then outlet colse and display time */ }
          displayStatus = true
          const { hour, minute, timeType } = TimeFormat12(outletTime[0].start_time)
          displayTime = (`${hour}:${minute} ${timeType}`)
          const duration = TimeDuration(date, outletTime[0].start_time)
          const dayDiff = DayDifferenceByWeek(currentDay?.id, checkedDay?.id)
          if (dayDiff > 0) {
            timeDuration = (`${dayDiff} Day`)
          } else if (duration?.hours > 0) {
            timeDuration = (`${duration?.hours} Hours : ${duration?.minutes + 1} Minutes`)
          } else {
            timeDuration = (`${duration?.minutes + 1} Minutes`)
          }
          isBreake = true
          break
        }
      } else {
        {/* check next day */ }
        const nextDay = (checkedDay?.id <= 5) ? (checkedDay?.id + 1) : 0
        const nextCheckDay = getDayObject(nextDay)
        checkedDay = nextCheckDay
      }
      if (index === 6) {
        displayStatus = true
        const { hour, minute, timeType } = TimeFormat12(outletTime[0]?.start_time)
        displayTime = (`${hour}:${minute} ${timeType}`)
        timeDuration = '7 Days'
        isBreake = true
        break
      }
    }
    return ({
      display_status: displayStatus,
      display_day: checkedDay,
      display_time: displayTime,
      display_duration: timeDuration,
    })
  } else {
    {/* all week days status false, so default outlet open*/ }
    return ({
      display_status: false,
      display_day: currentDay,
      display_time: displayTime,
      display_duration: timeDuration,
    })
  }
}

export const OutletOpenTime = async (datetime, outletsTimeSlots, response, holidaysList = []) => {
  const currentDate = datetime ? new Date(datetime) : new Date()
  const currentDay = datetime ? getDayObject(new Date(datetime).getDay()) : getDayObject(new Date().getDay())
  {/* Check outletsTimeSolts array length */ }
  if (outletsTimeSlots?.length > 0 && currentDay?.short) {
    const outletStatusTrue = outletsTimeSlots?.filter((item) => item?.status)
    if (outletStatusTrue?.length > 0) {
      const publicHoliday = outletStatusTrue?.filter(item => item?.day === "public_holiday")
      const evePublicHoliday = outletStatusTrue?.filter(item => item?.day === "eve_of_public_holiday")
      const weekDays = outletStatusTrue?.filter(item => (item?.day !== "public_holiday" && item?.day !== "eve_of_public_holiday"))
      {/* Check current day is holiday or eve holiday */ }
      const today_holiday = holidaysList?.length > 0 ? holidaysList?.find((holiday) => {
        const holiDate = new Date(holiday?.date)?.toDateString()
        return currentDate?.toDateString() === holiDate
      }) : undefined
      const today_eve_holiday = holidaysList?.length > 0 ? holidaysList?.find((holiday) => {
        const eveDate = new Date(new Date(holiday?.date)?.setDate(new Date(holiday?.date)?.getDate() - 1))?.toDateString()
        return currentDate?.toDateString() === eveDate
      }) : undefined
      {/* if current day holiday or eve holiday then check first day status true */ }
      const current_DayStatus = weekDays?.find((week) => week?.day === currentDay?.short)
      if (current_DayStatus?.day) {
        if (today_holiday?.type === "National holiday") {
          {/* if current day is holiday */ }
          if (publicHoliday[0]?.normal_operating_hour) {
            const CheckOutletResponse = CheckNormalOutletStatus(weekDays, currentDate)
            response(CheckOutletResponse)
          } else {
            // show holiday timing 
            const CheckOutletResponse = CheckOutletStatus(publicHoliday, currentDate)
            if (CheckOutletResponse?.status === 'outlet-close') {
              const { hour, minute, timeType } = TimeFormat12(CheckOutletResponse?.data?.start_time)
              const displayTime = (`${hour}:${minute} ${timeType}`)
              const duration = TimeDuration(currentDate, CheckOutletResponse?.data?.start_time)
              let timeDuration;
              if (duration?.hours > 0) {
                timeDuration = (`${duration?.hours} Hours : ${duration?.minutes + 1} Minutes`)
              } else {
                timeDuration = (`${duration?.minutes + 1} Minutes`)
              }
              response({
                display_status: true,
                display_day: currentDay,
                display_time: displayTime,
                display_duration: timeDuration,
              })
            } else if (CheckOutletResponse?.status === 'outlet-open') {
              response({
                display_status: false,
                display_day: currentDay,
                display_time: '12:00',
                display_duration: "0 Minutes",
              })
            }
          }
        } else if (today_eve_holiday?.type === "National holiday") {
          {/* if current day is eve holiday */ }
          if (evePublicHoliday[0]?.normal_operating_hour) {
            const CheckOutletResponse = CheckNormalOutletStatus(weekDays, currentDate)
            response(CheckOutletResponse)
          } else {
            // show holiday timing 
            const CheckOutletResponse = CheckOutletStatus(evePublicHoliday, currentDate)
            if (CheckOutletResponse?.status === 'outlet-close') {
              const { hour, minute, timeType } = TimeFormat12(CheckOutletResponse?.data?.start_time)
              const displayTime = (`${hour}:${minute} ${timeType}`)
              const duration = TimeDuration(currentDate, CheckOutletResponse?.data?.start_time)
              let timeDuration;
              if (duration?.hours > 0) {
                timeDuration = (`${duration?.hours} Hours : ${duration?.minutes + 1} Minutes`)
              } else {
                timeDuration = (`${duration?.minutes + 1} Minutes`)
              }
              response({
                display_status: true,
                display_day: currentDay,
                display_time: displayTime,
                display_duration: timeDuration,
              })
            } else if (CheckOutletResponse?.status === 'outlet-open') {
              response({
                display_status: false,
                display_day: currentDay,
                display_time: '12:00',
                display_duration: "0 Minutes",
              })
            }
          }
        } else {
          {/* if current day is not holiday or eve holiday then check outlet open timing */ }
          const CheckOutletResponse = CheckNormalOutletStatus(weekDays, currentDate)
          response(CheckOutletResponse)
        }
      } else {
        {/* check next outlet time for display when outlet will open */ }
        const CheckOutletResponse = CheckNormalOutletStatus(weekDays, currentDate)
        response(CheckOutletResponse)
      }
    } else {
      {/* Default outlet is open */ }
      response({
        display_status: false,
        display_day: currentDay,
        display_time: '12:00',
        display_duration: "0 Minutes",
      })
    }
  } else {
    {/* Default outlet is open */ }
    response({
      display_status: false,
      display_day: currentDay,
      display_time: '12:00',
      display_duration: "0 Minutes",
    })
  }
}

export const RemoveTypenameObject = ({ __typename, ...data }) => data;
export const RemoveTypenameArray = (dataArr) => (dataArr?.map(({ __typename, ...data }) => data))

export const DietaryPrefrenceIcons = (Arr) => {
  let result = []
  if (Arr) {
    for (const obj of Arr) {
      const [filterValue] = dietaryPrefrences?.filter(item => item?.value === obj)
      result.push(filterValue)
    }
  }
  return result
}

export const AllergyIcons = (Arr) => {
  let result = []
  if (Arr) {
    for (const obj of Arr) {
      const [filterValue] = allergiesOptions?.filter(item => item?.value === obj)
      result.push(filterValue)
    }
  }
  return result
}

export const InfiniteScrollPagination = (totalRecord = 0, pageIndex = 0, pageSize = 0) => {
  const totalPages = Math?.ceil(totalRecord / pageSize)
  const newPageIndex = (pageIndex + 1)

  return (newPageIndex < totalPages) ? { pageIndex: newPageIndex, nextCall: true } : { pageIndex: pageIndex, nextCall: false }
}

export const ArrangeCribItems = async (array, date, holidays = []) => {
  if (!array || date === undefined || date === null) {
    console.error("Required Array as first parameter and date as second parameter in ArrangeCribItems function")
    return ({ open: [], close: [] })
  } else {
    const initialValue = array ? [...array] : [];
    let myResult = new Promise((resolve) => {
      let openOutletValue = []
      let closeOutletValue = []
      initialValue && initialValue?.forEach(async (element, index) => {
        const { __typename, ...value } = element
        const outletsTime = value?.crib_menu_items?.outlets?.outletsTimeSlots;
        await OutletOpenTime(date, outletsTime, (response) => {
          let displayStatus = response?.display_status
          if (displayStatus) {
            closeOutletValue.push(value)
          } else {
            openOutletValue.push(value)
          }
          if (index === (initialValue?.length - 1)) {
            const soldoutItems = openOutletValue?.filter(item => ((item?.crib_menu_items?.sold_out_status == "true") || ((item?.crib_menu_items?.sold_out_status == "false") && item?.crib_menu_items?.daily_stock_quantity <= 0)))
            const inStockItems = openOutletValue?.filter(item => ((item?.crib_menu_items?.sold_out_status == "false") && item?.crib_menu_items?.daily_stock_quantity > 0))
            const isStockAndSoldout = [...inStockItems, ...soldoutItems]
            resolve({ open: isStockAndSoldout, close: closeOutletValue })
          }
        }, holidays);
      });
    })
    return myResult
  }
}