import React, { useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'

const AlertPopup = (params) => {
    return (
        <Modal
            backdropClassName="action01"
            dialogClassName="modalSM"
            contentClassName="action03"
            centered
            show={params?.show || false}
            onHide={params?.onHide}>
            <Modal.Body className=" d-flex flex-column justify-content-center align-items-center border08 text-center py-5 bgtheme">
                {params?.icon}
                <h1 className="text-white fs32 regular">{params?.title}</h1>
                <p className="fs24 regular lh-sm mb-5 text-white">{params?.description}</p>
                {params?.children}
                <div>
                    {params?.button && <Button variant="light" className="text-nowrap rounded-pill bgwhite py-2 px-sm-5 themeColor border-0 fs26 me-3 text-capitalize" onClick={params?.onButton}>{params?.button}</Button>}
                    {params?.cancelButton && <Button variant="light" className="text-nowrap rounded-pill bgwhite py-2 px-sm-5 themeColor border-0 fs26" onClick={params?.onCancelButton}>{params?.cancelButton}</Button>}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AlertPopup