import React from 'react'
import { Loader } from 'rsuite';

const CustomSpinner = ({ children, ...params }) => {
  return (
    <>
      <Loader className='loadercolor' {...params} />
      {children}
    </>
  )
}

export default CustomSpinner
