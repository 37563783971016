import { BrowserRouter } from "react-router-dom";
import 'rsuite/dist/rsuite.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import MyRoute from "./MyRoute";
import { Suspense } from "react";
import PageLoading from "./components/spinner/PageLoading";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<PageLoading />}>
        <BrowserRouter>
          <MyRoute />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
